import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { authColors } from './auth/AuthStyles';
import { useTheme } from './ThemeContext';

const LoadingScreen = () => {
    const [show, setShow] = useState(false);
    const { isDarkMode } = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 300); // Reduced from 500ms for snappier response

        return () => clearTimeout(timer);
    }, []);

    const styles = {
        container: {
            background: isDarkMode 
                ? authColors.dark.background
                : authColors.light.background,
        },
        spinnerOuter: {
            borderColor: isDarkMode 
                ? 'rgba(134, 194, 50, 0.1)' 
                : 'rgba(13, 110, 253, 0.1)',
        },
        spinnerInner: {
            borderColor: isDarkMode 
                ? authColors.dark.primary
                : authColors.light.primary,
            borderTopColor: 'transparent'
        },
        text: {
            color: isDarkMode 
                ? authColors.dark.primary
                : authColors.light.primary,
        }
    };

    if (!show) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center"
            style={styles.container}
        >
            {/* Cyber grid background */}
            <div className="absolute inset-0" style={{
                backgroundImage: `radial-gradient(${isDarkMode ? '#86C232' : '#130200'} 1px, transparent 1px)`,
                backgroundSize: '50px 50px',
                opacity: 0.1,
                animation: 'gridMove 20s linear infinite'
            }}/>

            <div className="relative z-10 flex flex-col items-center justify-center">
                <motion.div 
                    className="relative w-24 h-24"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                >
                    <div className="absolute w-full h-full border-4 rounded-full"
                         style={styles.spinnerOuter} />
                    <div className="absolute w-full h-full border-4 rounded-full animate-spin"
                         style={styles.spinnerInner} />
                </motion.div>

                <motion.p
                    className="mt-8 text-xl font-semibold"
                    animate={{ opacity: [0.5, 1, 0.5] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    style={styles.text}
                >
                    Loading...
                </motion.p>
            </div>

            <style>
                {`
                @keyframes gridMove {
                    0% { transform: translateY(0); }
                    100% { transform: translateY(50px); }
                }
                
                * {
                    transition: none !important;
                }
                `}
            </style>
        </motion.div>
    );
};

export default LoadingScreen;