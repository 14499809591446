import { useEffect } from "react";
import { Route, Routes } from "react-router";
import Footer from "./components/Footer";
import GoogleLoading from "./components/GoogleLoading";
import LoadingScreen from './components/LoadingScreen';
import NavBar from "./components/NavBar";
import { ThemeProvider } from './components/ThemeContext';
import { ApiStatusProvider, useApiStatus } from './contexts/ApiStatusContext';
import { FooterProvider, useFooter } from './contexts/FooterContext';
import Home from "./pages/Home";
import MaintenanceWrapper from './pages/MaintenancePage';
import ExamPaperBuilder from "./pages/math/ExamPaperBuilder";
import ExamPaperRetriever from "./pages/math/ExamPaperRetriever";
import QuestionUploader from "./pages/math/QuestionUploader";
import TopicPaperGenerator from "./pages/math/TopicPaperGenerator";
import NotFound from "./pages/NotFound";
import LoggingOut from "./pages/oauth/LoggingOut";
import Login from "./pages/oauth/Login";
import ResetPassword from "./pages/oauth/ResetPassword";
import ResetPasswordRequest from "./pages/oauth/ResetPasswordRequest";
import SettingsPage from "./pages/oauth/SettingsPage";
import SignUp from "./pages/oauth/SignUp";
import Verification from "./pages/oauth/Verification";
import RevisionTimer from "./pages/RevisionTimer";
import MathPage from "./pages/subjects/MathPage";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import TermsOfService from "./pages/terms/TermsOfService";
import UserAgreement from "./pages/terms/UserAgreement";
import UserPage from "./pages/UserPage";
import { syncDyslexiaMode } from './utils/DyslexiaUtils';
import Url from "./utils/Url";
import useAuth from "./utils/useAuth";

declare global {
    interface Window {
        google: any;
    }
}

const AppContent = () => {
    const { isApiReady, isLoading, error } = useApiStatus();
    const {jwt, role} = useAuth(null);
    const isAdmin = role === 'ADMIN';
    const { showFooter } = useFooter();

    useEffect(() => {
        if (jwt && isApiReady) {
            syncDyslexiaMode(jwt);
        }
    }, [jwt, isApiReady]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900">
                <div className="max-w-md p-8 mx-auto text-center rounded-lg shadow-lg bg-red-50 dark:bg-red-900/20">
                    <svg className="w-16 h-16 mx-auto mb-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <h2 className="mb-4 text-2xl font-bold text-red-600 dark:text-red-400">Connection Error</h2>
                    <p className="text-gray-700 dark:text-gray-300">{error}</p>
                    <button 
                        onClick={() => window.location.reload()}
                        className="px-4 py-2 mt-6 text-white transition-colors bg-red-600 rounded hover:bg-red-700">
                        Refresh Page
                    </button>
                </div>
            </div>
        );
    }

    return (
        <MaintenanceWrapper isAdmin={isAdmin}>

            <div className="flex flex-col min-h-screen app-container">
                <NavBar role={role}/>
                <div className="flex-grow content">
                    <Routes>
                        {jwt ? (
                            <>
                                <Route path={Url.HOME} element={<Home/>}/>
                                <Route path={Url.USER_PAGE} element={<UserPage/>}/>
                                <Route path={Url.SETTINGS_PAGE} element={<SettingsPage/>}/>
                                <Route path={Url.MATH} element={<MathPage/>}/>
                                <Route path={Url.MATH_EXAM_PAPER_RETRIEVAL} element={<ExamPaperRetriever/>}/>
                                <Route path={Url.MATH_TOPIC_PAPER_GENERATOR} element={<TopicPaperGenerator/>}/>
                                <Route path={Url.MATH_EXAM_PAPER_BUILDER} element={<ExamPaperBuilder/>}/>
                                {isAdmin &&
                                    <Route path={Url.MATH_EXAM_QUESTION_UPLOADER} element={<QuestionUploader/>}/>}
                                <Route path={Url.REVISION_TIMER} element={<RevisionTimer/>}/>
                                <Route path={Url.USER_AGREEMENT} element={<UserAgreement/>}/>
                                <Route path={Url.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                <Route path={Url.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                <Route path={Url.LOGGING_OUT} element={<LoggingOut/>}/>
                            </>
                        ) : (
                            <>
                                <Route path={Url.HOME} element={<Home/>}/>
                                <Route path={Url.GOOGLE_LOADING} element={<GoogleLoading/>}/>
                                <Route path={Url.LOGIN} element={<Login/>}/>
                                <Route path={Url.SIGN_UP} element={<SignUp/>}/>
                                <Route path={Url.VERIFICATION} element={<Verification/>}/>
                                <Route path={Url.RESET_PASSWORD_REQUEST} element={<ResetPasswordRequest/>}/>
                                <Route path={Url.RESET_PASSWORD} element={<ResetPassword/>}/>
                                <Route path={Url.USER_AGREEMENT} element={<UserAgreement/>}/>
                                <Route path={Url.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                <Route path={Url.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                <Route path={Url.LOGGING_OUT} element={<LoggingOut/>}/>
                            </>
                        )}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
                {showFooter && <Footer/>}
            </div>
        </MaintenanceWrapper>
    );
};

function App() {
    return (
        <ApiStatusProvider>
            <FooterProvider>
                <ThemeProvider>
                    <AppContent />
                </ThemeProvider>
            </FooterProvider>
        </ApiStatusProvider>
    );
}

export default App;
