import { StatusAPIUrls, UserInfoAPIUrls } from "./APIUrls";

const CACHE_KEY = 'dyslexiaMode';
const CACHE_TIMESTAMP_KEY = 'dyslexiaModeCacheTimestamp';
const CACHE_DURATION = 1000 * 60 * 60 * 24; // 24 hours

export const getDyslexiaModeFromCache = (): boolean | null => {
    const saved = localStorage.getItem(CACHE_KEY);
    const timestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
    
    if (!saved || !timestamp) return null;
    
    // Check if cache is still valid
    if (Date.now() - Number(timestamp) > CACHE_DURATION) {
        localStorage.removeItem(CACHE_KEY);
        localStorage.removeItem(CACHE_TIMESTAMP_KEY);
        return null;
    }
    
    return JSON.parse(saved);
};

export const setDyslexiaMode = (isDyslexic: boolean): void => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(isDyslexic));
    localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
    
    if (isDyslexic) {
        document.body.classList.add('dyslexia-mode');
    } else {
        document.body.classList.remove('dyslexia-mode');
    }
};

export const clearDyslexiaCache = (): void => {
    localStorage.removeItem(CACHE_KEY);
    localStorage.removeItem(CACHE_TIMESTAMP_KEY);
    document.body.classList.remove('dyslexia-mode');
};

export const checkServerStatus = async (): Promise<boolean> => {
    try {
        const response = await fetch(StatusAPIUrls.STATUS, { method: 'GET' });
        return response.ok;
    } catch {
        return false;
    }
};

export const syncDyslexiaMode = async (jwt: string | null): Promise<boolean | null> => {
    if (!jwt) return null;
    
    try {
        const isServerUp = await checkServerStatus();
        if (!isServerUp) {
            const cachedMode = getDyslexiaModeFromCache();
            if (cachedMode !== null) {
                setDyslexiaMode(cachedMode);
                return cachedMode;
            }
            return null;
        }

        const response = await fetch(UserInfoAPIUrls.IS_DYSLEXIC, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        });

        if (response.ok) {
            const isDyslexic = await response.json();
            setDyslexiaMode(isDyslexic);
            return isDyslexic;
        }
        return null;
    } catch (error) {
        console.error('Error syncing dyslexia mode:', error);
        const cachedMode = getDyslexiaModeFromCache();
        if (cachedMode !== null) {
            setDyslexiaMode(cachedMode);
            return cachedMode;
        }
        return null;
    }
};