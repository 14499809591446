import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { authColors } from "../../components/auth/AuthStyles";
import { useTheme } from "../../components/ThemeContext";

const LoggingOut = () => {
    const navigate = useNavigate();
    const { isDarkMode, theme } = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 2000); // Reduced from 3000 to make it snappier

        return () => clearTimeout(timer);
    }, [navigate]);

    const styles = {
        container: {
            background: isDarkMode 
                ? authColors.dark.background
                : authColors.light.background,
            minHeight: 'calc(100vh - 64px)',
        },
        text: {
            color: isDarkMode ? authColors.dark.primary : authColors.light.primary,
        }
    };

    return (
        <div className={`flex flex-col items-center justify-center ${theme.background}`}
             style={styles.container}>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center"
            >
                <h1 className="mb-4 text-4xl font-extrabold" style={styles.text}>
                    Logging out
                </h1>
                <div className="flex justify-center space-x-2">
                    <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1, repeat: Infinity, repeatDelay: 0 }}
                        className="text-3xl"
                        style={styles.text}
                    >
                        .
                    </motion.span>
                    <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1, repeat: Infinity, repeatDelay: 0.2 }}
                        className="text-3xl"
                        style={styles.text}
                    >
                        .
                    </motion.span>
                    <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1, repeat: Infinity, repeatDelay: 0.4 }}
                        className="text-3xl"
                        style={styles.text}
                    >
                        .
                    </motion.span>
                </div>
            </motion.div>
        </div>
    );
};

export default LoggingOut;
