
import React, { createContext, useContext, useEffect, useState } from 'react';
import { StatusAPIUrls } from '../utils/APIUrls';

interface ApiStatusContextType {
    isApiReady: boolean;
    isLoading: boolean;
    error: string | null;
}

const ApiStatusContext = createContext<ApiStatusContextType>({
    isApiReady: false,
    isLoading: true,
    error: null
});

export const ApiStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isApiReady, setIsApiReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const checkApiStatus = async () => {
            try {
                const response = await fetch(StatusAPIUrls.STATUS);
                if (response.ok) {
                    setIsApiReady(true);
                    setIsLoading(false);
                    setError(null);
                } else {
                    throw new Error('API is not ready');
                }
            } catch (err) {
                setError('Unable to connect to the server');
                if (retryCount < 3) { // Retry up to 3 times
                    setTimeout(() => {
                        setRetryCount(prev => prev + 1);
                    }, 2000); // Retry every 2 seconds
                } else {
                    setIsLoading(false);
                }
            }
        };

        checkApiStatus();
    }, [retryCount]);

    return (
        <ApiStatusContext.Provider value={{ isApiReady, isLoading, error }}>
            {children}
        </ApiStatusContext.Provider>
    );
};

export const useApiStatus = () => useContext(ApiStatusContext);