import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls, VerificationAPIUrls } from "../../utils/APIUrls";
import { getCurrentStyles } from "../../utils/Common";
import { initializeGoogleSignIn } from '../../utils/googleSignIn';
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validateEmail, validateHCaptcha, validateHCaptchaError, validatePassword, validatePasswordMatch } from "../../utils/validationUtils";
import { useAuthStyles, authColors } from '../../components/auth/AuthStyles';

const SignUp: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [hcaptchaToken, setHecaptchaToken] = useState('');
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const { isDarkMode, theme } = useTheme();
    const styles = useAuthStyles(isDarkMode);

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate, setError);
    }, [navigate]);

    const handleVerificationSuccess = async (token: React.SetStateAction<string>, ekey: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(VerificationAPIUrls.VERIFY_HCAPTCHA, {
                hcaptchaToken: token,
                url: getWebsiteUrl()
            });
            
            if (response.data?.success) {
                setHecaptchaToken(token);
                setError('');
            } else {
                setError(response.data?.message || 'CAPTCHA verification failed');
            }
        } catch (error) {
            setError(validateHCaptchaError(error));
        } finally {
            setIsVerifying(false);
        }
    };

    const validateFormData = useCallback(() => {
        const emailError = validateEmail(email);
        if (emailError) return emailError;

        const passwordError = validatePassword(password);
        if (passwordError) return passwordError;

        const passwordMatchError = validatePasswordMatch(password, confirmPassword);
        if (passwordMatchError) return passwordMatchError;

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) return captchaError;

        if (!acceptTerms) return 'You must accept the terms and conditions to sign up.';

        return null;
    }, [email, password, confirmPassword, hcaptchaToken, acceptTerms]);

    const handleSignUpCallback = useCallback(async () => {
        const error = validateFormData();
        if (error) {
            setError(error);
            return;
        }

        setIsSigningUp(true);
        try {
            const response = await axios.post(AuthAPIUrls.SIGNUP, {
                email,
                password,
                hcaptchaToken,
                url: getWebsiteUrl()
            });
    
            if (response.status === 201) {
                navigate(`/verification/${email}/signup`);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 409:
                            setError('This email is already registered. Please use a different email.');
                            break;
                        case 400:
                            const apiErrors = error.response.data?.errors;
                            if (apiErrors) {
                                const errorMessages = Object.values(apiErrors).join(' ');
                                setError(errorMessages);
                            } else {
                                setError('Invalid data submitted. Please check your inputs.');
                            }
                            break;
                        default:
                            setError(error.response.data || 'An error occurred during signup, please report this issue.');
                            break;
                    }
                } else if (error.request) {
                    setError('No response from server. Please check your connection.');
                } else {
                    setError('An unexpected error occurred');
                }
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsSigningUp(false);
        }
    }, [email, password, hcaptchaToken, validateFormData, navigate]);

    const handleInputChange = useCallback((field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        switch (field) {
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
        setError(''); // Clear error on any input change
    }, []);

    const handleTermsToggle = useCallback(() => {
        setAcceptTerms(prev => !prev);
        setError(''); // Clear error when terms are accepted
    }, []);

    return <>
        <Helmet>
            <title>Revise Wizard - Sign Up</title>
            <meta name="description" content="Sign up for a Revise Wizard account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.SIGN_UP}/>
        </Helmet>
        <div className={`relative flex flex-col items-center justify-center flex-grow py-6 overflow-hidden md:py-8 ${theme.background} ${theme.text}`}
             style={styles.container}>
            
            {/* Cyber grid background */}
            <div className="absolute inset-0" style={{
                backgroundImage: `radial-gradient(${isDarkMode ? '#86C232' : '#130200'} 1px, transparent 1px)`,
                backgroundSize: '50px 50px',
                opacity: 0.1,
                animation: 'gridMove 20s linear infinite'
            }}/>

            {/* Decorative geometric shapes */}
            <div className="absolute top-0 left-0 w-64 h-64 transform rotate-45 -translate-x-32 -translate-y-32"
                 style={{
                     background: isDarkMode 
                         ? 'linear-gradient(45deg, #86C232 0%, transparent 60%)' 
                         : 'linear-gradient(45deg, #130200 0%, transparent 60%)',
                     opacity: 0.1,
                     filter: 'blur(40px)'
                 }}/>

            <motion.div 
                className={`relative z-10 w-full max-w-md p-8 rounded-2xl backdrop-blur-sm ${theme.cardBg}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                style={styles.card}
            >
                <motion.h1
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="mb-8 text-4xl font-extrabold text-center"
                    style={{color: getCurrentStyles(isDarkMode).color}}
                >
                    Sign Up
                </motion.h1>

                {/* Updated Google Sign-in Section */}
                <div className="mb-8">
                    <div className="flex items-center justify-center mb-6">
                        <div id="google-signin-button" className="transition-transform transform hover:scale-105"/>
                    </div>
                    
                    <div className="relative flex items-center justify-center">
                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
                        <span className="px-4 text-sm text-gray-500 dark:text-gray-400">
                            or continue with email
                        </span>
                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
                    </div>
                </div>

                {/* Error message with updated styling */}
                {error && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="px-4 py-3 mb-6 text-center text-white bg-red-500 rounded-lg backdrop-blur-sm"
                    >
                        {error}
                    </motion.div>
                )}

                <div className="mb-4">
                    <label className="block mb-2 text-lg transition-colors duration-300"
                           style={{color: getCurrentStyles(isDarkMode).color}}>
                        Email:
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={handleInputChange('email')}
                        className="w-full px-4 py-2 rounded-lg focus:scale-[1.02] transform transition-all duration-300"
                        style={styles.input}
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-lg transition-colors duration-300"
                           style={{color: getCurrentStyles(isDarkMode).color}}>
                        Password:
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handleInputChange('password')}
                            className="w-full px-4 py-2 pr-12 rounded-lg focus:scale-[1.02] transform transition-all duration-300"
                            style={styles.input}
                        />
                        <button
                            type="button"
                            onMouseDown={() => setShowPassword(true)}
                            onMouseUp={() => setShowPassword(false)}
                            onMouseLeave={() => setShowPassword(false)}
                            className="absolute p-2 transition-all duration-300 -translate-y-1/2 rounded-full right-2 top-1/2 hover:bg-opacity-10 password-reveal-btn"
                            style={{
                                color: isDarkMode ? '#86C232' : '#130200',
                            }}
                        >
                            {showPassword ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-lg transition-colors duration-300"
                           style={{color: getCurrentStyles(isDarkMode).color}}>
                        Confirm Password:
                    </label>
                    <div className="relative">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={handleInputChange('confirmPassword')}
                            className="w-full px-4 py-2 pr-12 rounded-lg focus:scale-[1.02] transform transition-all duration-300"
                            style={styles.input}
                        />
                        <button
                            type="button"
                            onMouseDown={() => setShowConfirmPassword(true)}
                            onMouseUp={() => setShowConfirmPassword(false)}
                            onMouseLeave={() => setShowConfirmPassword(false)}
                            className="absolute p-2 transition-all duration-300 -translate-y-1/2 rounded-full right-2 top-1/2 hover:bg-opacity-10 password-reveal-btn"
                            style={{
                                color: isDarkMode ? '#86C232' : '#130200',
                            }}
                        >
                            {showConfirmPassword ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                <div className="mb-4">
                    <input
                        type="checkbox"
                        checked={acceptTerms}
                        onChange={handleTermsToggle}
                    />
                    <span className="ml-2" style={{color: getCurrentStyles(isDarkMode).color}}>
                        I accept the <Link to="/user-agreement"
                                           className="text-blue-500 hover:underline">User Agreement</Link>,
                        <Link to="/terms-of-service" className="text-blue-500 hover:underline"> Terms of Service</Link>, and
                        <Link to="/privacy-policy" className="text-blue-500 hover:underline"> Privacy Policy</Link>.
                    </span>
                </div>

                {/* Updated HCaptcha container */}
                <div className="mb-6">
                    <div className="flex items-center justify-center p-4 transition-all duration-300 rounded-lg backdrop-blur-sm"
                         style={{
                             background: isDarkMode ? 'rgba(40, 44, 52, 0.6)' : 'rgba(255, 255, 255, 0.6)',
                             border: `1px solid ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'}`,
                             boxShadow: `0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.1)' : 'rgba(19, 2, 0, 0.05)'}`,
                         }}>
                        <HCaptcha
                            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                            onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                            theme={isDarkMode ? 'dark' : 'light'}
                            size="normal"
                        />
                    </div>
                </div>

                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full px-6 py-3 mt-6 text-lg font-semibold transition-all duration-300 rounded-lg"
                    onClick={handleSignUpCallback}
                    style={styles.button}
                    disabled={isVerifying || isSigningUp}
                >
                    {isVerifying ? 'Please wait...' : isSigningUp ? (
                        <div className="flex space-x-1">
                            <span className="animate-bounce">.</span>
                            <span className="animate-bounce animation-delay-200">.</span>
                            <span className="animate-bounce animation-delay-400">.</span>
                        </div>
                    ) : 'Sign Up'}
                </motion.button>
            </motion.div>
        </div>

        <style>
            {`
            @keyframes gridMove {
                0% { transform: translateY(0); }
                100% { transform: translateY(50px); }
            }
            
            input:focus {
                box-shadow: 0 0 20px ${isDarkMode 
                    ? `rgba(${authColors.dark.primary}, 0.4)` 
                    : `rgba(${authColors.light.primary}, 0.4)`};
                border-color: ${isDarkMode 
                    ? authColors.dark.primary 
                    : authColors.light.primary};
            }
            
            .google-button {
                transition: all 0.3s ease;
            }
            
            .google-button:hover {
                transform: translateY(-2px);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            }

            #google-signin-button {
                min-height: 40px;
                min-width: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            #google-signin-button > div {
                transition: transform 0.3s ease;
            }
            
            #google-signin-button:hover > div {
                transform: scale(1.05);
            }
            
            .google-button-wrapper {
                position: relative;
                overflow: hidden;
                border-radius: 4px;
            }
            
            .google-button-wrapper::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: ${isDarkMode 
                    ? 'linear-gradient(45deg, rgba(134, 194, 50, 0.1), transparent)'
                    : 'linear-gradient(45deg, rgba(19, 2, 0, 0.1), transparent)'};
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            
            .google-button-wrapper:hover::after {
                opacity: 1;
            }

            /* HCaptcha container styles */
            .h-captcha {
                display: flex;
                justify-content: center;
                transform: scale(1);
                transition: transform 0.3s ease;
            }
            
            .h-captcha:hover {
                transform: scale(1.02);
            }
            
            /* Custom frame styling */
            iframe[src*="hcaptcha"] {
                border-radius: 8px;
                transition: all 0.3s ease;
            }
            
            iframe[src*="hcaptcha"]:hover {
                box-shadow: 0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'};
            }

            /* Password reveal button styles */
            .password-reveal-btn {
                opacity: 0.7;
                transition: all 0.2s ease;
            }

            .password-reveal-btn:hover {
                opacity: 1;
                background: ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'};
                color: ${isDarkMode ? '#86C232' : '#130200'} !important;
            }

            .password-reveal-btn:active {
                transform: scale(0.95) translateY(-50%);
                background: ${isDarkMode ? 'rgba(134, 194, 50, 0.3)' : 'rgba(19, 2, 0, 0.15)'};
            }

            /* Theme transition overrides */
            input, button, a {
                transition: all 0.3s ease !important;
            }

            .h-captcha {
                transition: transform 0.3s ease !important;
            }

            /* Updated hover effects */
            button:hover {
                box-shadow: 0 0 30px ${isDarkMode 
                    ? `rgba(${authColors.dark.primary}, 0.5)` 
                    : `rgba(${authColors.light.primary}, 0.5)`};
                background: ${isDarkMode 
                    ? `linear-gradient(45deg, ${authColors.dark.secondary}, ${authColors.dark.primary})` 
                    : `linear-gradient(45deg, ${authColors.light.secondary}, ${authColors.light.primary})`};
            }

            input:focus {
                box-shadow: 0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.4)' : 'rgba(19, 2, 0, 0.3)'};
                transform: scale(1.01);
            }

            /* Link styles */
            a {
                color: ${isDarkMode ? authColors.dark.primary : authColors.light.primary};
                text-decoration: none;
            }

            a:hover {
                color: ${isDarkMode ? authColors.dark.secondary : authColors.light.secondary};
                text-decoration: underline;
            }
            `}
        </style>
    </>;
};

// Memoize the entire component
export default memo(SignUp);
