import { UserInfoAPIUrls } from "./APIUrls";

const ROLE_CACHE_KEY = 'userRole';
const ROLE_CACHE_TIMESTAMP_KEY = 'userRoleCacheTimestamp';
const CACHE_DURATION = 1000 * 60 * 60 * 24; // 24 hours

export const getRoleFromCache = (): string | null => {
    const saved = localStorage.getItem(ROLE_CACHE_KEY);
    const timestamp = localStorage.getItem(ROLE_CACHE_TIMESTAMP_KEY);
    
    if (!saved || !timestamp) return null;
    
    if (Date.now() - Number(timestamp) > CACHE_DURATION) {
        localStorage.removeItem(ROLE_CACHE_KEY);
        localStorage.removeItem(ROLE_CACHE_TIMESTAMP_KEY);
        return null;
    }
    
    return saved;
};

export const setRoleCache = (role: string): void => {
    localStorage.setItem(ROLE_CACHE_KEY, role);
    localStorage.setItem(ROLE_CACHE_TIMESTAMP_KEY, Date.now().toString());
};

export const clearRoleCache = (): void => {
    localStorage.removeItem(ROLE_CACHE_KEY);
    localStorage.removeItem(ROLE_CACHE_TIMESTAMP_KEY);
};

export const syncUserRole = async (jwt: string | null, abortController?: AbortController): Promise<string | null> => {
    if (!jwt) return null;
    
    try {
        const response = await fetch(UserInfoAPIUrls.USER_ROLE, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            signal: abortController?.signal
        });

        if (response.ok) {
            const data = await response.json();
            setRoleCache(data.role);
            return data.role;
        }
        return getRoleFromCache();
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
            return getRoleFromCache();
        }
        console.error('Error syncing user role:', error);
        return getRoleFromCache();
    }
};