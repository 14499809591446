import { useEffect, useState } from "react";
import { NavigateFunction } from "react-router";
import { useApiStatus } from '../contexts/ApiStatusContext';
import { getCookie, removeCookie } from "./Cookies";
import { clearDyslexiaCache } from './DyslexiaUtils';
import { clearRoleCache, getRoleFromCache, syncUserRole } from './RoleUtils';
import Url from './Url';

const useAuth = (navigate: NavigateFunction | null) => {
    const { isApiReady } = useApiStatus();
    const [jwt, setJwt] = useState<string | null>(getCookie('jwt'));
    const [role, setRole] = useState<string | null>(getRoleFromCache());

    useEffect(() => {
        if (jwt && isApiReady) {
            syncUserRole(jwt).then(fetchedRole => {
                setRole(fetchedRole);
            });
        } else {
            setRole(null);
        }
    }, [jwt, isApiReady]);

    const logout = () => {
        removeCookie('jwt');
        setJwt(null);
        setRole(null);
        clearRoleCache();
        clearDyslexiaCache();
        if (navigate) {
            navigate(Url.LOGGING_OUT);
        }
    };

    return { jwt, role, logout };
};

export default useAuth;
