import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { useTheme } from "../../components/ThemeContext";
import { InfoAPIUrls } from "../../utils/APIUrls";
import Url, { getWebsiteUrl } from "../../utils/Url";

const MathPage: React.FC = () => {
    const { isDarkMode, theme } = useTheme();
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);

    useEffect(() => {
        const fetchTopics = async () => {
            setLoadingTopics(true);
            let level = ['aslevel', 'alevel'][Math.floor(Math.random() * 2)];
            try {
                if (!InfoAPIUrls.TOPIC_LIST) {
                    throw new Error('API URL is undefined');
                }
                const response = await axios.get(InfoAPIUrls.TOPIC_LIST, {params: {level}});
                const topicsData = response.data.topics.map((topic: string) => ({value: topic, label: topic}));
                setTopics(topicsData);
            } catch (error: any) {
                console.error('Error fetching topics:', error.message);
                setTopics([]);
            } finally {
                setLoadingTopics(false);
            }
        };

        fetchTopics();
    }, []);

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Math</title>
                <meta name="description"
                      content="Revise Wizard's math section, where you can access past papers, topical questions, and notes to help you prepare for your exams."/>
                <link rel="canonical" href={getWebsiteUrl() + Url.MATH}/>
            </Helmet>

            <div className={`min-h-screen ${theme.background}`}>
                <div className="container px-4 py-12 mx-auto">
                    {/* Hero Section */}
                    <div className={`relative p-8 mb-12 overflow-hidden rounded-3xl ${theme.cardBg} backdrop-blur-md bg-opacity-90 hover:shadow-xl transition-all duration-300`}>
                        <div className="absolute inset-0 bg-gradient-to-r from-green-500/10 to-transparent dark:from-green-500/20"/>
                        <h1 className={`relative mb-4 text-4xl font-bold bg-gradient-to-r ${
                            isDarkMode ? 'from-white to-gray-300' : 'from-gray-900 to-gray-700'
                        } bg-clip-text text-transparent`}>
                            Mathematics Hub
                        </h1>
                        <p className={`relative max-w-2xl text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            Master your math skills with our comprehensive suite of tools. Practice with past papers,
                            build custom exams, and access topic-specific resources.
                        </p>
                    </div>

                    {/* Features Grid */}
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                        {/* Paper Retrieval Card */}
                        <motion.div
                            whileHover={{ y: -5 }}
                            className="relative overflow-hidden group"
                        >
                            <div className="absolute inset-0 transition-all duration-300 opacity-0 bg-gradient-to-r from-green-500/20 to-blue-500/20 group-hover:opacity-100 blur"/>
                            <Link to={Url.MATH_EXAM_PAPER_RETRIEVAL} className={`block p-6 transition-all duration-300 ${theme.cardBg} backdrop-blur-md rounded-2xl group-hover:shadow-xl`}>
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Paper Retrieval</h2>
                                    <div className="p-2 transition-all duration-300 rounded-lg bg-green-500/10 group-hover:bg-green-500/20">
                                        <svg className="w-6 h-6 text-green-600 dark:text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                        </svg>
                                    </div>
                                </div>
                                <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Access a comprehensive collection of past papers. Practice with real exam questions to boost your confidence.
                                </p>
                                <span className={`inline-flex items-center text-sm font-medium ${isDarkMode ? 'text-green-400' : 'text-green-600'} transition-transform group-hover:translate-x-1`}>
                                    Access Papers
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                                    </svg>
                                </span>
                            </Link>
                        </motion.div>

                        {/* Topical Paper Generator Card */}
                        <motion.div
                            whileHover={{ y: -5 }}
                            className="relative overflow-hidden group"
                        >
                            <div className="absolute inset-0 transition-all duration-300 opacity-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 group-hover:opacity-100 blur"/>
                            <Link to={Url.MATH_TOPIC_PAPER_GENERATOR} className={`block p-6 transition-all duration-300 ${theme.cardBg} backdrop-blur-md rounded-2xl group-hover:shadow-xl`}>
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Topic Generator</h2>
                                    <div className="p-2 transition-all duration-300 rounded-lg bg-purple-500/10 group-hover:bg-purple-500/20">
                                        <svg className="w-6 h-6 text-purple-600 dark:text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
                                        </svg>
                                    </div>
                                </div>
                                <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Create custom practice papers by topic. Focus on specific areas and track your progress.
                                </p>
                                <span className={`inline-flex items-center text-sm font-medium ${isDarkMode ? 'text-purple-400' : 'text-purple-600'} transition-transform group-hover:translate-x-1`}>
                                    Generate Papers
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                                    </svg>
                                </span>
                            </Link>
                        </motion.div>

                        {/* Exam Paper Builder Card */}
                        <motion.div
                            whileHover={{ y: -5 }}
                            className="relative overflow-hidden group"
                        >
                            <div className="absolute inset-0 transition-all duration-300 opacity-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 group-hover:opacity-100 blur"/>
                            <Link to={Url.MATH_EXAM_PAPER_BUILDER} className={`block p-6 transition-all duration-300 ${theme.cardBg} backdrop-blur-md rounded-2xl group-hover:shadow-xl`}>
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Paper Builder</h2>
                                    <div className="p-2 transition-all duration-300 rounded-lg bg-blue-500/10 group-hover:bg-blue-500/20">
                                        <svg className="w-6 h-6 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                        </svg>
                                    </div>
                                </div>
                                <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Build your own custom exam papers. Mix questions from different topics and create the perfect practice test.
                                </p>
                                <span className={`inline-flex items-center text-sm font-medium ${isDarkMode ? 'text-blue-400' : 'text-blue-600'} transition-transform group-hover:translate-x-1`}>
                                    Build Papers
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                                    </svg>
                                </span>
                            </Link>
                        </motion.div>

                        {/* Coming Soon Card */}
                        <motion.div
                            whileHover={{ y: -5 }}
                            className="relative overflow-hidden group md:col-span-2 lg:col-span-1"
                        >
                            <div className="absolute inset-0 transition-all duration-300 opacity-0 bg-gradient-to-r from-gray-500/10 to-gray-600/10 group-hover:opacity-100 blur"/>
                            <div className={`block p-6 transition-all duration-300 ${theme.cardBg} backdrop-blur-md rounded-2xl`}>
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Topical Notes</h2>
                                    <div className="p-2 transition-all duration-300 rounded-lg bg-gray-500/10">
                                        <svg className="w-6 h-6 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                        </svg>
                                    </div>
                                </div>
                                <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Coming Soon! Comprehensive topic notes and study guides to support your learning journey.
                                </p>
                                <span className={`inline-flex items-center text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Coming Soon
                                    <svg className="w-4 h-4 ml-1 animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                    </svg>
                                </span>
                            </div>
                        </motion.div>
                    </div>

                    {/* Topics Preview Section */}
                    <div className={`p-6 mt-12 overflow-hidden rounded-2xl ${theme.cardBg} backdrop-blur-md bg-opacity-90`}>
                        <h2 className={`mb-6 text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            Available Topics
                        </h2>
                        <div className="flex items-center space-x-4">
                            {loadingTopics ? (
                                <div className="flex space-x-2">
                                    <div className="w-2 h-2 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-100 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-200 bg-green-500 rounded-full animate-bounce"/>
                                </div>
                            ) : (
                                <ReactTyped
                                    strings={topics.map((topic) => topic.label)}
                                    typeSpeed={40}
                                    backSpeed={50}
                                    loop
                                    className={`text-lg font-medium ${isDarkMode ? 'text-green-400' : 'text-green-600'}`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MathPage;
