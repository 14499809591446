import axios from "axios";
import { saveAs } from 'file-saver';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import Select from 'react-select';
import { useTheme } from "../../components/ThemeContext";
import { PDFAPIUrls } from "../../utils/APIUrls";
import { getCookie, removeCookie, setCookie } from "../../utils/Cookies";
import { HTTPErrorCode } from '../../utils/HTTPCode';
import Url, { getWebsiteUrl } from "../../utils/Url";

const ExamPaperRetriever = () => {
    const { isDarkMode, theme } = useTheme();
    const [selectedLevel, setSelectedLevel] = useState<'gcse' | 'aslevel' | 'alevel'>('gcse');
    const [selectedExamBoard, setSelectedExamBoard] = useState<string | null>(null);
    const [selectedPaper, setSelectedPaper] = useState<{ value: string; label: string } | null>(null);
    const [selectedYear, setSelectedYear] = useState<{ value: number; label: string } | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<{ value: string; label: string } | null>(null);
    const [examBoards, setExamBoards] = useState<string[]>([]);
    const [papers, setPapers] = useState<{ value: string; label: string }[]>([]);
    const [years, setYears] = useState<{ value: number; label: string }[]>([]);
    const [months, setMonths] = useState<{ value: string; label: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPapers, setLoadingPapers] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchExamBoards();
    }, [selectedLevel]);

    const fetchExamBoards = async () => {
        try {
            const response = await axios.get(PDFAPIUrls.EXAM_BOARDS);
            setExamBoards(response.data);
        } catch (error: any) {
            console.error('Error fetching exam boards:', error);
            setExamBoards([]); // Clear exam boards on error
        }
    };

    const fetchPapers = async (examBoard: string, level: string) => {
        setLoadingPapers(true);
        try {
            const response = await axios.get(PDFAPIUrls.PAST_PAPER_INFO, {params: {examBoard, level}});
            const data = response.data;
            const papersData = data.names.map((paper: string) => ({value: paper, label: paper}));
            const yearsData = data.years.map((year: number) => ({value: year, label: year.toString()}));
            const monthsData = data.months.map((month: string) => ({value: month, label: month}));
            setPapers(papersData);
            setYears(yearsData);
            setMonths(monthsData);
        } catch (error: any) {
            console.error('Error fetching papers:', error);
            setPapers([]); // Clear papers on error
            setYears([]); // Clear years on error
            setMonths([]); // Clear months on error
        } finally {
            setLoadingPapers(false);
        }
    };

    const handleLevelChange = (newLevel: 'gcse' | 'aslevel' | 'alevel') => {
        setSelectedLevel(newLevel);
        setCookie('selectedLevel', newLevel);
        setSelectedExamBoard(null);
        setSelectedPaper(null);
        setSelectedYear(null);
        setSelectedMonth(null);
        setPapers([]);
        setYears([]);
        setMonths([]);
    };

    const handleExamBoardChange = (examBoard: string) => {
        setSelectedExamBoard(examBoard);
        fetchPapers(examBoard, selectedLevel);
    };

    const handlePaperChange = (selectedOption: any) => {
        setSelectedPaper(selectedOption);
    };

    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
    };

    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
    };

    const handleError = useCallback((error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case HTTPErrorCode.UNAUTHORIZED:
                case HTTPErrorCode.FORBIDDEN:
                    alert('Authorization error. Please log in again.');
                    removeCookie('jwt');
                    navigate('/');
                    window.location.reload();
                    break;
                case HTTPErrorCode.NOT_FOUND:
                    alert('The requested paper was not found.');
                    break;
                case HTTPErrorCode.INTERNAL_SERVER_ERROR:
                    alert('Server error. Please try again later.');
                    break;
                case HTTPErrorCode.SERVICE_UNAVAILABLE:
                    alert('Service temporarily unavailable. Please try again later.');
                    break;
                default:
                    alert('An error occurred while retrieving the paper.');
            }
        } else if (error.request) {
            alert('Network error. Please check your connection.');
        } else {
            alert('An unexpected error occurred');
        }
    }, [navigate]);

    const handleRetrievePaper = async () => {
        if (!selectedExamBoard || !selectedLevel || !selectedPaper || !selectedYear || !selectedMonth) return;

        setLoading(true);

        const requestData = {
            examBoard: selectedExamBoard,
            level: selectedLevel,
            year: selectedYear.value,
            paperName: selectedPaper.value,
            month: selectedMonth.value,
            jwt: `Bearer ${getCookie('jwt')}` 
        };

        try {
            await axios.post(PDFAPIUrls.PAST_PAPER, requestData, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${getCookie('jwt')}`
                }
            })
                .then((response: any) => {
                    if (response.data.size === 0) {
                        alert('No paper found for the selected options');
                        return;
                    }

                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    saveAs(blob, `${selectedPaper.value}-${selectedMonth.value}-${selectedYear.value}.pdf`);
                })
                .catch((error: any) => {
                    handleError(error);
                });

        } catch (error) {
            console.error('Error during paper retrieval:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fix the Select component styles
    const selectStyles = {
        control: (base: any) => ({
            ...base,
            background: isDarkMode ? 'rgb(55, 65, 81)' : 'white',
            borderColor: isDarkMode ? 'rgb(75, 85, 99)' : 'rgb(209, 213, 219)',
            borderRadius: '0.5rem',
            padding: '0.25rem',
            '&:hover': {
                borderColor: isDarkMode ? 'rgb(107, 114, 128)' : 'rgb(156, 163, 175)'
            }
        }),
        menu: (base: any) => ({
            ...base,
            background: isDarkMode ? 'rgb(31, 41, 55)' : 'white',
            border: isDarkMode ? '1px solid rgb(75, 85, 99)' : '1px solid rgb(209, 213, 219)',
            borderRadius: '0.5rem',
            overflow: 'hidden'
        }),
        option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
            ...base,
            backgroundColor: state.isSelected
                ? isDarkMode ? 'rgb(55, 65, 81)' : 'rgb(243, 244, 246)'
                : state.isFocused
                    ? isDarkMode ? 'rgb(75, 85, 99)' : 'rgb(229, 231, 235)'
                    : 'transparent',
            color: isDarkMode ? 'rgb(229, 231, 235)' : 'rgb(17, 24, 39)',
            cursor: 'pointer'
        }),
        singleValue: (base: any) => ({
            ...base,
            color: isDarkMode ? 'rgb(229, 231, 235)' : 'rgb(17, 24, 39)'
        }),
        input: (base: any) => ({
            ...base,
            color: isDarkMode ? 'rgb(229, 231, 235)' : 'rgb(17, 24, 39)'
        })
    };

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Exam Paper Retriever</title>
                <meta name="description" content="Retrieve past exam papers for GCSE, AS-level, and A-level exams from various exam boards."/>
                <link rel="canonical" href={getWebsiteUrl() + Url.MATH_EXAM_PAPER_RETRIEVAL}/>
            </Helmet>
            <div className={`min-h-screen ${theme.background}`}>
                <div className="container max-w-4xl px-4 py-8 mx-auto space-y-6">
                    {/* Header Section */}
                    <div className={`p-6 ${theme.cardBg} shadow-lg rounded-2xl backdrop-blur-sm bg-opacity-90 transition-all duration-300 hover:shadow-xl`}>
                        <h1 className={`text-3xl font-bold bg-gradient-to-r ${
                            isDarkMode ? 'from-white to-gray-300' : 'from-gray-900 to-gray-700'
                        } bg-clip-text text-transparent`}>
                            Exam Paper Retriever
                        </h1>
                        <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            Access past exam papers and enhance your revision
                        </p>
                    </div>

                    {/* Main Content */}
                    <div className={`p-6 ${theme.cardBg} shadow-lg rounded-2xl backdrop-blur-sm bg-opacity-90`}>
                        {/* Level Selection */}
                        <div className="mb-8">
                            <h2 className={`mb-4 text-sm font-medium tracking-wider uppercase ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                Academic Level
                            </h2>
                            <div className="flex flex-wrap gap-3">
                                {['gcse', 'aslevel', 'alevel'].map((level) => (
                                    <button
                                        key={level}
                                        onClick={() => handleLevelChange(level as 'gcse' | 'aslevel' | 'alevel')}
                                        className={`px-6 py-3 rounded-xl transition-all duration-300 ${
                                            selectedLevel === level
                                                ? 'bg-gradient-to-r from-green-500 to-green-600 text-white shadow-lg shadow-green-500/25'
                                                : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-700/50 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
                                        }`}
                                    >
                                        {level === 'gcse' ? 'GCSE' : level === 'aslevel' ? 'AS Level' : 'A Level'}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Exam Board Selection */}
                        <div className="mb-8">
                            <h2 className="mb-4 text-sm font-medium tracking-wider text-gray-900 uppercase dark:text-white">
                                Exam Board
                            </h2>
                            <div className="flex flex-wrap gap-3">
                                {examBoards.map((board) => (
                                    <button
                                        key={board}
                                        onClick={() => handleExamBoardChange(board)}
                                        className={`px-6 py-3 rounded-xl transition-all duration-300 ${
                                            selectedExamBoard === board
                                                ? 'bg-gradient-to-r from-green-500 to-green-600 text-white shadow-lg shadow-green-500/25'
                                                : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-700/50 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
                                        }`}
                                    >
                                        {board}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {selectedExamBoard && !loadingPapers && (
                            <div className="space-y-6">
                                {/* Paper Selection */}
                                <div>
                                    <label className={`block mb-2 text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                        Select Paper
                                    </label>
                                    <Select
                                        options={papers}
                                        value={selectedPaper}
                                        onChange={handlePaperChange}
                                        styles={selectStyles}
                                        isDisabled={!selectedExamBoard}
                                    />
                                </div>

                                {/* Year Selection */}
                                <div>
                                    <label className={`block mb-2 text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                        Select Year
                                    </label>
                                    <Select
                                        options={years}
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        styles={selectStyles}
                                        isDisabled={!selectedExamBoard}
                                    />
                                </div>

                                {/* Month Selection */}
                                <div>
                                    <label className={`block mb-2 text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                        Select Month
                                    </label>
                                    <Select
                                        options={months}
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        styles={selectStyles}
                                        isDisabled={!selectedExamBoard}
                                    />
                                </div>
                            </div>
                        )}

                        {loadingPapers && (
                            <div className="flex items-center justify-center p-8 space-x-2">
                                <div className="w-2 h-2 bg-green-500 rounded-full animate-bounce"/>
                                <div className="w-2 h-2 delay-100 bg-green-500 rounded-full animate-bounce"/>
                                <div className="w-2 h-2 delay-200 bg-green-500 rounded-full animate-bounce"/>
                            </div>
                        )}

                        {/* Retrieve Button */}
                        {selectedExamBoard && selectedPaper && selectedYear && selectedMonth && (
                            <motion.div className="mt-8">
                                <button
                                    onClick={handleRetrievePaper}
                                    disabled={loading}
                                    className={`w-full px-6 py-3 text-white transition-all duration-300 rounded-xl ${
                                        loading
                                            ? 'bg-gray-500 cursor-not-allowed'
                                            : 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 shadow-lg shadow-green-500/25'
                                    }`}
                                >
                                    {loading ? (
                                        <div className="flex items-center justify-center space-x-2">
                                            <div className="w-4 h-4 border-2 border-white rounded-full border-t-transparent animate-spin"/>
                                            <span>Retrieving Paper...</span>
                                        </div>
                                    ) : (
                                        'Retrieve Exam Paper'
                                    )}
                                </button>
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExamPaperRetriever;
