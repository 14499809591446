import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from "axios";
import { motion } from 'framer-motion';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { authColors, useAuthStyles } from "../../components/auth/AuthStyles";
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls, VerificationAPIUrls } from "../../utils/APIUrls";
import { getCurrentStyles } from "../../utils/Common";
import { initializeGoogleSignIn } from '../../utils/googleSignIn';
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validateEmail, validateHCaptcha, validateHCaptchaError } from "../../utils/validationUtils";


const Login: React.FC = () => {
    const { isDarkMode, theme } = useTheme();
    const styles = useAuthStyles(isDarkMode);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate, setError);
    }, [navigate]);

    const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setError('');
    }, []);

    const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setError('');
    }, []);

    const handleVerificationSuccess = useCallback(async (token: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(VerificationAPIUrls.VERIFY_HCAPTCHA, {
                hcaptchaToken: token,
                url: getWebsiteUrl()
            });
            
            if (response.data?.success) {
                setHecaptchaToken(token);
                setError('');
            } else {
                setError(response.data?.message || 'CAPTCHA verification failed');
            }
        } catch (error) {
            setError(validateHCaptchaError(error));
        } finally {
            setIsVerifying(false);
        }
    }, []);

    const handleLogin = useCallback(async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        if (!password.trim()) {
            setError('Password cannot be empty');
            return;
        }

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) {
            setError(captchaError);
            return;
        }

        setIsLoggingIn(true);
        try {
            const response = await axios.post(AuthAPIUrls.LOGIN, {
                email,
                password,
                hcaptchaToken,
                url: getWebsiteUrl()
            });
    
            if (response.status === 202) {
                navigate(`/verification/${email}/login`);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            const apiErrors = error.response.data?.errors;
                            if (apiErrors) {
                                const errorMessages = Object.values(apiErrors).join(' ');
                                setError(errorMessages);
                            } else {
                                setError('Invalid data submitted. Please check your inputs.');
                            }
                            break;
                        case 404:
                            setError('Email does not exist. Please check and try again.');
                            break;
                        case 403:
                            setError('This email is linked to OAuth authentication. Please login with Google or reset your password.');
                            break;
                        case 401:
                            setError('Invalid password. Please check and try again.');
                            break;
                        default:
                            setError(error.response.data || 'An error occurred during login');
                            break;
                    }
                } else if (error.request) {
                    setError('No response from server. Please check your connection.');
                } else {
                    setError('An unexpected error occurred');
                }
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsLoggingIn(false);
        }
    }, [email, password, hcaptchaToken]);

    return <>
        <Helmet>
            <title>Revise Wizard - Login</title>
            <meta name="description" content="Login to your Revise Wizard account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.LOGIN}/>
        </Helmet>
        <div className={`relative min-h-screen flex flex-col items-center justify-center py-6 px-4 auth-container ${theme.text}`}
             style={styles.container}>
            
            {/* Cyber grid background */}
            <div className="absolute inset-0" style={{
                backgroundImage: `radial-gradient(${isDarkMode ? '#86C232' : '#130200'} 1px, transparent 1px)`,
                backgroundSize: '50px 50px',
                opacity: 0.1,
                animation: 'gridMove 20s linear infinite'
            }}/>

            {/* Decorative geometric shapes */}
            <div className="absolute top-0 left-0 w-64 h-64 transform rotate-45 -translate-x-32 -translate-y-32"
                 style={{
                     background: isDarkMode 
                         ? 'linear-gradient(45deg, #86C232 0%, transparent 60%)' 
                         : 'linear-gradient(45deg, #130200 0%, transparent 60%)',
                     opacity: 0.1,
                     filter: 'blur(40px)'
                 }}/>

            <motion.div 
                className="relative z-10 w-full max-w-md p-8 auth-card rounded-2xl backdrop-blur-sm"
                style={styles.card}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>

                <motion.h1
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="mb-8 text-4xl font-extrabold text-center"
                    style={{color: getCurrentStyles(isDarkMode).color}}
                >
                    Login
                </motion.h1>

                {/* Updated Google Sign-in Section */}
                <div className="mb-8">
                    <div className="flex items-center justify-center mb-6">
                        <div id="google-signin-button" className="transition-transform transform hover:scale-105"/>
                    </div>
                    
                    <div className="relative flex items-center justify-center">
                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
                        <span className="px-4 text-sm text-gray-500 dark:text-gray-400">
                            or continue with email
                        </span>
                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-600"></div>
                    </div>
                </div>

                {/* Error message with updated styling */}
                {error && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="px-4 py-3 mb-6 text-center text-white bg-red-500 rounded-lg backdrop-blur-sm"
                    >
                        {error}
                    </motion.div>
                )}

                <div className="mb-4">
                    <label className="block mb-2 text-lg transition-colors duration-300"
                           style={{color: getCurrentStyles(isDarkMode).color}}>
                        Email:
                    </label>
                    <input
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        className="auth-input w-full px-4 py-2 rounded-lg focus:scale-[1.02] transform"
                        style={styles.input}
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-lg transition-colors duration-300"
                           style={{color: getCurrentStyles(isDarkMode).color}}>
                        Password:
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                            className="auth-input w-full px-4 py-2 pr-12 rounded-lg focus:scale-[1.02] transform"
                            style={styles.input}
                        />
                        <button
                            type="button"
                            onMouseDown={() => setShowPassword(true)}
                            onMouseUp={() => setShowPassword(false)}
                            onMouseLeave={() => setShowPassword(false)}
                            className="absolute p-2 transition-all duration-300 -translate-y-1/2 rounded-full right-2 top-1/2 hover:bg-opacity-10 password-reveal-btn"
                            style={{
                                color: isDarkMode ? '#86C232' : '#130200',
                            }}
                        >
                            {showPassword ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                <div className="mb-4 text-center">
                    <Link to="/reset-password-request"
                          className="transition duration-300 hover:text-opacity-80"
                          style={{
                              color: isDarkMode ? '#86C232' : '#130200',
                              textDecoration: 'none',
                          }}
                    >
                        Forgot your password? Click here
                    </Link>
                </div>

                {/* Updated HCaptcha container */}
                <div className="mb-6">
                    <div className="flex items-center justify-center p-4 transition-all duration-300 rounded-lg backdrop-blur-sm"
                         style={{
                             background: isDarkMode ? 'rgba(40, 44, 52, 0.6)' : 'rgba(255, 255, 255, 0.6)',
                             border: `1px solid ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'}`,
                             boxShadow: `0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.1)' : 'rgba(19, 2, 0, 0.05)'}`,
                         }}>
                        <HCaptcha
                            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                            onVerify={(token) => handleVerificationSuccess(token)}
                            theme={isDarkMode ? 'dark' : 'light'}
                            size="normal"
                        />
                    </div>
                </div>

                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full px-6 py-3 text-lg font-semibold rounded-lg auth-button"
                    style={styles.button}
                    onClick={handleLogin}
                    disabled={isVerifying || isLoggingIn}
                >
                    {isVerifying ? 'Please wait...' : isLoggingIn ? (
                        <div className="flex justify-center space-x-1">
                            <span className="animate-bounce">.</span>
                            <span className="animate-bounce animation-delay-200">.</span>
                            <span className="animate-bounce animation-delay-400">.</span>
                        </div>
                    ) : 'Login'}
                </motion.button>
            </motion.div>
        </div>

        <style>
            {`
                @keyframes gridMove {
                    0% { transform: translateY(0); }
                    100% { transform: translateY(50px); }
                }
                
                input:focus {
                    box-shadow: 0 0 20px ${isDarkMode 
                        ? `rgba(${authColors.dark.primary}, 0.4)` 
                        : `rgba(${authColors.light.primary}, 0.4)`};
                    border-color: ${isDarkMode 
                        ? authColors.dark.primary 
                        : authColors.light.primary};
                }
                
                button:hover {
                    box-shadow: 0 0 30px ${isDarkMode 
                        ? `rgba(${authColors.dark.primary}, 0.5)` 
                        : `rgba(${authColors.light.primary}, 0.5)`};
                    background: ${isDarkMode 
                        ? `linear-gradient(45deg, ${authColors.dark.secondary}, ${authColors.dark.primary})` 
                        : `linear-gradient(45deg, ${authColors.light.secondary}, ${authColors.light.primary})`};
                }

                #google-signin-button {
                    min-height: 40px;
                    min-width: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                
                #google-signin-button > div {
                    transition: transform 0.3s ease;
                }
                
                #google-signin-button:hover > div {
                    transform: scale(1.05);
                }
                
                .google-button-wrapper {
                    position: relative;
                    overflow: hidden;
                    border-radius: 4px;
                }
                
                .google-button-wrapper::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: ${isDarkMode 
                        ? 'linear-gradient(45deg, rgba(134, 194, 50, 0.1), transparent)'
                        : 'linear-gradient(45deg, rgba(19, 2, 0, 0.1), transparent)'};
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
                
                .google-button-wrapper:hover::after {
                    opacity: 1;
                }

                /* HCaptcha container styles */
                .h-captcha {
                    display: flex;
                    justify-content: center;
                    transform: scale(1);
                    transition: transform 0.3s ease;
                }
                
                .h-captcha:hover {
                    transform: scale(1.02);
                }
                
                /* Custom frame styling */
                iframe[src*="hcaptcha"] {
                    border-radius: 8px;
                    transition: all 0.3s ease;
                }
                
                iframe[src*="hcaptcha"]:hover {
                    box-shadow: 0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'};
                }

                /* Password reveal button styles */
                .password-reveal-btn {
                    opacity: 0.7;
                    transition: all 0.2s ease;
                }

                .password-reveal-btn:hover {
                    opacity: 1;
                    background: ${isDarkMode ? 'rgba(134, 194, 50, 0.2)' : 'rgba(19, 2, 0, 0.1)'};
                    color: ${isDarkMode ? '#9BDC3B' : '#130200'} !important;
                }

                .password-reveal-btn:active {
                    transform: scale(0.95) translateY(-50%);
                    background: ${isDarkMode ? 'rgba(134, 194, 50, 0.3)' : 'rgba(19, 2, 0, 0.15)'};
                }
            `}
        </style>
    </>;
};

export default memo(Login);
