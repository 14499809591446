import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from '../utils/Cookies';
import '../styles/transitions.css';

interface ThemeContextProps {
    isDarkMode: boolean;
    toggleTheme: () => void;
    theme: ThemeConfig;
}

export interface ThemeConfig {
    background: string;
    text: string;
    primary: string;
    secondary: string;
    accent: string;
    border: string;
    cardBg: string;
    buttonBg: string;
    buttonText: string;
    inputBg: string;
    inputText: string;
    error: string;
    success: string;
}

const lightTheme: ThemeConfig = {
    background: 'bg-gray-100',
    text: 'text-gray-900',
    primary: 'bg-green-600',
    secondary: 'bg-blue-600',
    accent: '#130200',
    border: 'border-gray-200',
    cardBg: 'bg-white',
    buttonBg: 'bg-green-600',
    buttonText: 'text-white',
    inputBg: 'bg-white',
    inputText: 'text-gray-900',
    error: 'text-red-600',
    success: 'text-green-600'
};

const darkTheme: ThemeConfig = {
    background: 'bg-gray-900',
    text: 'text-gray-100',
    primary: 'bg-green-500',
    secondary: 'bg-blue-500',
    accent: '#86C232',
    border: 'border-gray-700',
    cardBg: 'bg-gray-800',
    buttonBg: 'bg-green-500',
    buttonText: 'text-white',
    inputBg: 'bg-gray-700',
    inputText: 'text-gray-100',
    error: 'text-red-400',
    success: 'text-green-400'
};

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const getInitialTheme = (): boolean => {
        const savedTheme = getCookie('theme');
        if (savedTheme) {
            return savedTheme === 'dark';
        }
        
        const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setCookie('theme', systemPreference ? 'dark' : 'light');
        return systemPreference;
    };

    const [isDarkMode, setIsDarkMode] = useState<boolean>(getInitialTheme);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e: MediaQueryListEvent) => {
            if (!getCookie('theme')) {
                setIsDarkMode(e.matches);
                setCookie('theme', e.matches ? 'dark' : 'light');
            }
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            document.documentElement.classList.remove('light');
        } else {
            document.documentElement.classList.add('light');
            document.documentElement.classList.remove('dark');
        }
        setCookie('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    const toggleTheme = () => {
        document.documentElement.classList.add('transitioning');
        
        setIsDarkMode(prev => !prev);
        
        setTimeout(() => {
            document.documentElement.classList.remove('transitioning');
        }, 200);
    };

    return (
        <ThemeContext.Provider value={{ 
            isDarkMode, 
            toggleTheme, 
            theme: isDarkMode ? darkTheme : lightTheme 
        }}>
            {children}
        </ThemeContext.Provider>
    );
};
