import axios from "axios";
import { saveAs } from 'file-saver';
import { motion } from 'framer-motion';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import Select from 'react-select';
import { useTheme } from "../../components/ThemeContext";
import { InfoAPIUrls, PDFAPIUrls } from "../../utils/APIUrls";
import { getCustomStyles } from "../../utils/Common";
import { getCookie, removeCookie, setCookie } from '../../utils/Cookies';
import { HTTPErrorCode } from '../../utils/HTTPCode';
import Url, { getWebsiteUrl } from "../../utils/Url";

const TopicPaperGenerator = () => {
    const {isDarkMode} = useTheme();
    const [selectedOptions, setSelectedOptions] = useState<{ value: string; label: string }[]>([]);
    const [questionsPerTopic, setQuestionsPerTopic] = useState<number>(5); // Default value
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const cachedSelectedLevel = getCookie('selectedLevel');
    const defaultSelectedLevel = cachedSelectedLevel && ['gcse', 'aslevel', 'alevel'].includes(cachedSelectedLevel) ? cachedSelectedLevel : 'gcse';
    const [selectedLevel, setSelectedLevel] = useState<'gcse' | 'aslevel' | 'alevel'>(defaultSelectedLevel as 'gcse' | 'aslevel' | 'alevel');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTopics(selectedLevel);
    }, [selectedLevel]);

    const fetchTopics = async (level: string) => {
        setLoadingTopics(true);
        try {
            const response = await axios.get(InfoAPIUrls.TOPIC_LIST, {params: {level}});
            const topicsData = response.data.topics.map((topic: string) => ({value: topic, label: topic}));
            setTopics(topicsData);
        } catch (error: any) {
            console.error('Error fetching topics:', error);
            setTopics([]); // Clear topics on error
        } finally {
            setLoadingTopics(false);
        }
    };

    const handleLevelChange = (newLevel: 'gcse' | 'aslevel' | 'alevel') => {
        setSelectedLevel(newLevel);
        setCookie('selectedLevel', newLevel);
        fetchTopics(newLevel);
        setSelectedOptions([]);
    };

    const handleTopicChange = (selectedOptions: any) => {
        setSelectedOptions(selectedOptions);
    };

    const handleQuestionsPerTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setQuestionsPerTopic(isNaN(value) ? 1 : Math.max(1, Math.min(10, value)));
    };


    const customStyles = getCustomStyles(isDarkMode);

    const handleCreatePaper = async () => {
        setLoading(true);

        try {
            const endpoint = selectedOptions.length === 1 ? PDFAPIUrls.TOPIC : PDFAPIUrls.TOPICS;

            const response = await axios.get(endpoint, {
                params: {
                    [selectedOptions.length === 1 ? 'topic' : 'topics']: selectedOptions.map(topic => topic.value).join(','),
                    year: selectedLevel,
                    amount: questionsPerTopic
                },
                headers: {
                    'Authorization': `Bearer ${getCookie('jwt')}`
                },
                responseType: 'blob',
            });

            if (response.data.size === 0) {
                alert('No questions found for the selected topics');
                return;
            }

            const blob = new Blob([response.data], {type: 'application/pdf'});
            saveAs(blob, 'topic_paper.pdf');
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case HTTPErrorCode.UNAUTHORIZED:
                    alert('Unauthorized access. Please log in again.');
                    removeCookie('jwt');
                    navigate('/');
                    break;
                case HTTPErrorCode.FORBIDDEN:
                    alert('Access forbidden. Please check your permissions.');
                    break;
                case HTTPErrorCode.NOT_FOUND:
                    alert('No questions found for the selected topics.');
                    break;
                case HTTPErrorCode.INTERNAL_SERVER_ERROR:
                    alert('Server error. Please try again later.');
                    break;
                default:
                    alert('An error occurred while generating the paper.');
            }
        } else if (error.request) {
            alert('Network error. Please check your connection.');
        } else {
            alert('An unexpected error occurred');
        }
    };

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Topic Paper Generator</title>
                <meta name="description" content="Generate topic papers for GCSE, AS-level, and A-level exams."/>
                <link rel="canonical" href={getWebsiteUrl() + Url.MATH_TOPIC_PAPER_GENERATOR}/>
            </Helmet>
            <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900">
                <div className="container max-w-4xl px-4 py-8 mx-auto">
                    {/* Header with Gradient */}
                    <div className="relative p-6 mb-8 overflow-hidden bg-white/90 backdrop-blur-md rounded-2xl dark:bg-gray-800/90">
                        <div className="absolute inset-0 bg-gradient-to-r from-green-500/10 to-transparent dark:from-green-500/20"/>
                        <h1 className="relative mb-4 text-3xl font-bold text-transparent bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300 bg-clip-text">
                            {selectedLevel === 'gcse'
                                ? 'GCSE Topic Generator'
                                : selectedLevel === 'alevel'
                                    ? 'A-level Topic Generator'
                                    : 'AS-level Topic Generator'}
                        </h1>
                        <p className="relative text-sm text-gray-600 dark:text-gray-400">
                            Create custom practice papers focused on specific topics
                        </p>
                    </div>

                    {/* Main Content Card */}
                    <div className="p-6 bg-white shadow-xl rounded-2xl dark:bg-gray-800">
                        {/* Level Selection */}
                        <div className="mb-8">
                            <h2 className="mb-4 text-sm font-medium tracking-wider text-gray-900 uppercase dark:text-white">
                                Academic Level
                            </h2>
                            <div className="flex flex-wrap gap-3">
                                {['gcse', 'aslevel', 'alevel'].map((level) => (
                                    <button
                                        key={level}
                                        onClick={() => handleLevelChange(level as 'gcse' | 'aslevel' | 'alevel')}
                                        className={`px-6 py-3 rounded-xl transition-all duration-300 ${
                                            selectedLevel === level
                                                ? 'bg-gradient-to-r from-green-500 to-green-600 text-white shadow-lg shadow-green-500/25'
                                                : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-700/50 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
                                        }`}
                                    >
                                        {level === 'gcse' ? 'GCSE' : level === 'aslevel' ? 'AS Level' : 'A Level'}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Topic Selection */}
                        <div className="mb-8">
                            <h2 className="mb-4 text-sm font-medium tracking-wider text-gray-900 uppercase dark:text-white">
                                Select Topics
                            </h2>
                            {loadingTopics ? (
                                <div className="flex items-center justify-center p-8 space-x-2">
                                    <div className="w-2 h-2 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-100 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-200 bg-green-500 rounded-full animate-bounce"/>
                                </div>
                            ) : (
                                <Select
                                    isMulti
                                    options={topics}
                                    value={selectedOptions}
                                    onChange={handleTopicChange}
                                    styles={customStyles}
                                    className="relative z-20"
                                />
                            )}
                        </div>

                        {/* Questions per Topic */}
                        <div className="mb-8">
                            <h2 className="mb-4 text-sm font-medium tracking-wider text-gray-900 uppercase dark:text-white">
                                Questions per Topic
                            </h2>
                            <input
                                type="number"
                                value={questionsPerTopic}
                                onChange={handleQuestionsPerTopicChange}
                                className="w-full px-4 py-3 text-gray-700 transition-all bg-gray-50 rounded-xl dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-green-500 dark:focus:ring-green-600"
                                min="1"
                                max="10"
                            />
                        </div>

                        {/* Notice Card */}
                        <div className="p-4 mb-8 overflow-hidden rounded-xl bg-amber-500/10 backdrop-blur-sm">
                            <div className="flex items-center space-x-3">
                                <svg className="w-5 h-5 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                <p className="text-sm text-amber-700 dark:text-amber-400">
                                    Paper generation may take a few moments. Thank you for your patience!
                                </p>
                            </div>
                        </div>

                        {/* Generate Button */}
                        <motion.div>
                            <button
                                onClick={handleCreatePaper}
                                disabled={loading || selectedOptions.length === 0}
                                className={`w-full px-6 py-3 text-white transition-all duration-300 rounded-xl ${
                                    loading || selectedOptions.length === 0
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 shadow-lg shadow-green-500/25'
                                }`}
                            >
                                {loading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <div className="w-4 h-4 border-2 border-white rounded-full border-t-transparent animate-spin"/>
                                        <span>Generating Paper...</span>
                                    </div>
                                ) : (
                                    'Generate Topic Paper'
                                )}
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(TopicPaperGenerator);
