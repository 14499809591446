import { format } from 'date-fns';
import { BarChart2, Clock, Crown, FileText, Settings, UserCircle } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router";
import { animated, useSpring } from 'react-spring';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTheme } from '../components/ThemeContext';
import { UserInfoAPIUrls } from "../utils/APIUrls";
import { getCurrentStyles } from '../utils/Common';
import { getCookie } from '../utils/Cookies';
import Url, { getWebsiteUrl } from '../utils/Url';
import { getCardStyles, getCommonStyles } from '../utils/themeUtils';

interface RevisionTimeDone {
    totalMinutesDone: number;
    dailyActivity: null;
    formattedTotalTime: string;
}

interface DailyActivity {
    date: string;
    minutesDone: number;
}

interface TotalRevisionTime {
    totalMinutesDone: number;
    dailyActivity: DailyActivity[];
    formattedTotalTime: string;
}

interface UserProfileData {
    email: string;
    name: string | null;
    subscription: {
        type: {
            name: string;
            aiAccess: boolean;
            maxAmountOfBookletsAllowedToGeneratePerMonth: number;
        };
        expirationDate?: string;
    };
    numberOfExamPapersGenerated: number;
    totalRevisionTimeToday: RevisionTimeDone;
    totalRevisionTimeAllTime: TotalRevisionTime;
}

const UserProfile = () => {
    const [profile, setProfile] = useState<UserProfileData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { isDarkMode, theme } = useTheme();
    const navigate = useNavigate();
    const jwtToken = getCookie('jwt');
    const [showGraph, setShowGraph] = useState(false);

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    const cardAnimations = [
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 200,
            config: { tension: 280, friction: 20 }
        }),
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 400,
            config: { tension: 280, friction: 20 }
        }),
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 600,
            config: { tension: 280, friction: 20 }
        }),
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 800,
            config: { tension: 280, friction: 20 }
        }),
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 1000,
            config: { tension: 280, friction: 20 }
        })
    ];

    const graphAnimation = useSpring({
        opacity: showGraph ? 1 : 0,
        transform: showGraph ? 'translateY(0)' : 'translateY(-20px)',
        config: { tension: 280, friction: 20 }
    });

    const getLast5Days = (): string[] => {
        const dates = [];
        for (let i = 4; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            dates.push(format(date, 'yyyy-MM-dd'));
        }
        return dates;
    };

    const prepareChartData = (dailyActivity: DailyActivity[] | null): DailyActivity[] => {
        const last5Days = getLast5Days();
        const activityMap = new Map(
            (dailyActivity || []).map(activity => [activity.date, activity.minutesDone])
        );

        return last5Days.map(date => ({
            date,
            minutesDone: activityMap.get(date) || 0
        }));
    };

    const fetchUserProfile = useCallback(async () => {
        try {
            const response = await fetch(UserInfoAPIUrls.INFO, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user profile');
            }

            const data = await response.json();
            console.log('Raw API response:', data);

            const userProfile: UserProfileData = {
                email: data.authenticationClaims.email,
                name: data.name,
                subscription: {
                    type: {
                        name: data.userMetadata.subscriptionPlan.name,
                        aiAccess: data.userMetadata.subscriptionPlan.aiAccess,
                        maxAmountOfBookletsAllowedToGeneratePerMonth: data.userMetadata.subscriptionPlan.maxAmountOfBookletsAllowedToGeneratePerMonth
                    },
                    expirationDate: data.userMetadata.subscriptionPlan.name !== 'Free' ? data.userMetadata.subscriptionExpirationDate : undefined
                },
                numberOfExamPapersGenerated: data.userMetadata.numberOfExamPapersGenerated,
                totalRevisionTimeToday: data.userMetadata.totalRevisionTimeToday,
                totalRevisionTimeAllTime: {
                    totalMinutesDone: data.userMetadata.totalRevisionTimeAllTime.totalMinutesDone,
                    formattedTotalTime: data.userMetadata.totalRevisionTimeAllTime.formattedTotalTime,
                    dailyActivity: data.userMetadata.totalRevisionTimeAllTime.dailyActivity || []
                }
            };
            setProfile(userProfile);
            
        } catch (err) {
            console.error('Error in fetchUserProfile:', err);
            setError('Failed to load profile data');
        } finally {
            setLoading(false);
        }
    }, [jwtToken]);

    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile, jwtToken]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="w-12 h-12 border-4 border-green-600 rounded-full animate-spin border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="p-6 bg-red-100 rounded-lg dark:bg-red-900">
                    <p className="text-center text-red-600 dark:text-red-200">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Profile</title>
                <meta name="description" content="Your Revise Wizard profile and statistics" />
                <link rel="canonical" href={getWebsiteUrl() + Url.USER_PAGE} />
            </Helmet>

            <div 
                className={`flex flex-col items-center flex-grow px-4 py-6 ${theme.background} ${theme.text}`}
                style={getCommonStyles(isDarkMode)}
            >
                <div className="w-full max-w-4xl space-y-8">
                    <animated.div 
                        style={{...fadeIn, ...getCardStyles(theme)}}
                        className={`overflow-hidden ${theme.cardBg} ${theme.text} shadow-lg rounded-xl backdrop-blur-sm bg-opacity-90`}
                    >
                        <div className="flex flex-col items-center p-8 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                            <div className="relative">
                                <UserCircle className="w-24 h-24 text-green-600"/>
                                <div className="absolute bottom-0 right-0 w-6 h-6 bg-green-500 border-4 border-white rounded-full dark:border-gray-800"></div>
                            </div>
                            <div className="text-center sm:text-left">
                                <h1 className={`text-3xl font-bold ${theme.text}`}>
                                    {profile?.name || profile?.email}
                                </h1>
                                {profile?.name && (
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                        {profile.email}
                                    </p>
                                )}
                                <div className="w-full my-4 border-b border-gray-300 dark:border-gray-700"></div>
                                <blockquote className={`text-lg italic ${theme.text}`}>
                                    <span className={`text-2xl font-semibold ${theme.text}`}>"</span>
                                    Success is the sum of small efforts, repeated day in and day out.
                                    <span className={`text-2xl font-semibold ${theme.text}`}>"</span>
                                </blockquote>
                                <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                    – Robert Collier
                                </p>
                            </div>
                        </div>
                    </animated.div>

                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
                        <animated.div 
                            style={{...cardAnimations[0], ...getCardStyles(theme)}}
                            className={`p-4 transition-shadow duration-300 ${theme.cardBg} ${theme.text} shadow-lg group rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90`}
                        >
                            <div className="flex items-start space-x-4">
                                <div className="p-3 transition-transform duration-300 bg-yellow-100 rounded-lg dark:bg-yellow-900 group-hover:scale-110">
                                    <Crown className="w-8 h-8 text-yellow-600 dark:text-yellow-400"/>
                                </div>
                                <div className="flex-1">
                                    <h3 className={`text-2xl font-semibold ${theme.text}`}>
                                        {profile?.subscription.type?.name} Plan
                                    </h3>
                                    <div className="mt-1 space-y-1">
                                        {profile?.subscription.expirationDate && (
                                            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                                Valid until {new Date(profile.subscription.expirationDate).toLocaleDateString()}
                                            </p>
                                        )}
                                        <p className={`text-sm ${isDarkMode ? 'text-amber-300' : 'text-amber-600'}`}>
                                            AI Access: {profile?.subscription.type?.aiAccess ? 'Yes' : 'No'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </animated.div>

                        <animated.div 
                            style={{...cardAnimations[1], ...getCardStyles(theme)}}
                            className={`p-4 transition-shadow duration-300 ${theme.cardBg} ${theme.text} shadow-lg group rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90`}
                        >
                            <div className="flex items-start space-x-4">
                                <div className="p-3 transition-transform duration-300 bg-blue-100 rounded-lg dark:bg-blue-900 group-hover:scale-110">
                                    <FileText className="w-8 h-8 text-blue-600 dark:text-blue-400"/>
                                </div>
                                <div className="flex-1">
                                    <h3 className={`text-2xl font-semibold ${theme.text} whitespace-nowrap`}>
                                        Papers Generated
                                    </h3>
                                    <p className={`mt-1 text-sm font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                                        {profile?.numberOfExamPapersGenerated || '0'} papers
                                    </p>
                                </div>
                            </div>
                        </animated.div>

                        <animated.div 
                            style={{...cardAnimations[2], ...getCardStyles(theme)}}
                            className={`p-4 transition-shadow duration-300 ${theme.cardBg} ${theme.text} shadow-lg group rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90`}
                        >
                            <div className="flex items-start space-x-4">
                                <div className="p-3 transition-transform duration-300 bg-green-100 rounded-lg dark:bg-green-900 group-hover:scale-110">
                                    <Clock className="w-8 h-8 text-green-600 dark:text-green-400"/>
                                </div>
                                <div className="flex-1">
                                    <h3 className={`text-2xl font-semibold ${theme.text}`}>
                                        Today's Revision
                                    </h3>
                                    <p className={`mt-1 text-sm font-medium ${isDarkMode ? 'text-green-300' : 'text-green-700'}`}>
                                        {profile?.totalRevisionTimeToday.formattedTotalTime}
                                    </p>
                                </div>
                            </div>
                        </animated.div>

                        <animated.div 
                            style={{...cardAnimations[3], ...getCardStyles(theme)}}
                            className={`p-4 transition-shadow duration-300 ${theme.cardBg} ${theme.text} shadow-lg group rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90`}
                        >
                            <div className="flex items-start space-x-4">
                                <div className="p-3 transition-transform duration-300 bg-purple-100 rounded-lg dark:bg-purple-900 group-hover:scale-110">
                                    <Clock className="w-8 h-8 text-purple-600 dark:text-purple-400"/>
                                </div>
                                <div className="flex-1">
                                    <h3 className={`text-2xl font-semibold ${theme.text}`}>
                                        Total Revision
                                    </h3>
                                    <p className={`mt-1 text-sm font-medium ${isDarkMode ? 'text-purple-300' : 'text-purple-700'}`}>
                                        {profile?.totalRevisionTimeAllTime.formattedTotalTime}
                                    </p>
                                </div>
                            </div>
                        </animated.div>

                        <animated.div 
                            style={{...cardAnimations[4], ...getCardStyles(theme)}}
                            className="col-span-full"
                        >
                            <button
                                onClick={() => setShowGraph(true)}
                                className="flex items-center justify-center w-full gap-2 px-6 py-4 font-semibold text-white transition-all duration-300 transform bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl hover:from-indigo-600 hover:to-purple-700 hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 shadow-lg hover:shadow-xl"
                            >
                                <BarChart2 className="w-5 h-5" />
                                <span>View Revision Progress</span>
                            </button>
                        </animated.div>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-4 mt-8 sm:flex-row">
                        <button
                            onClick={() => navigate(Url.MATH)}
                            className="flex items-center justify-center w-full px-8 py-4 space-x-2 font-semibold text-white transition duration-300 transform shadow-lg sm:w-auto bg-gradient-to-r from-green-600 to-green-500 rounded-xl hover:from-green-700 hover:to-green-600 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:shadow-xl"
                        >
                            <FileText className="w-5 h-5"/>
                            <span>Generate New Paper</span>
                        </button>

                        <button
                            onClick={() => navigate(Url.SETTINGS_PAGE)}
                            className="flex items-center justify-center w-full px-8 py-4 space-x-2 font-semibold text-gray-700 transition duration-300 transform bg-gray-100 shadow-lg sm:w-auto dark:bg-gray-700 dark:text-gray-300 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-600 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 hover:shadow-xl"
                        >
                            <Settings className="w-5 h-5"/>
                            <span>Account Settings</span>
                        </button>
                    </div>
                </div>
            </div>

            {showGraph && (
                <div className="fixed inset-0 z-50 flex items-center justify-center px-4 bg-black/50">
                    <animated.div 
                        style={graphAnimation}
                        className={`w-full max-w-4xl p-6 ${theme.cardBg} ${theme.text} shadow-2xl rounded-2xl`}
                    >
                        <div className="flex items-center justify-between mb-6">
                            <h3 className={`text-2xl font-bold ${theme.text}`}>
                                Revision Progress (Last 5 Days)
                            </h3>
                            <button
                                onClick={() => setShowGraph(false)}
                                className="p-2 text-gray-500 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        
                        {profile?.totalRevisionTimeAllTime.dailyActivity && profile.totalRevisionTimeAllTime.dailyActivity.length > 0 ? (
                            <div className="h-[400px] w-full">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={prepareChartData(profile?.totalRevisionTimeAllTime.dailyActivity)}
                                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#374151' : '#E5E7EB'} />
                                        <XAxis 
                                            dataKey="date" 
                                            stroke={isDarkMode ? '#fff' : '#000'}
                                            tick={{ fill: isDarkMode ? '#fff' : '#000' }}
                                            tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                        />
                                        <YAxis 
                                            stroke={isDarkMode ? '#fff' : '#000'}
                                            tick={{ fill: isDarkMode ? '#fff' : '#000' }}
                                            label={{ 
                                                value: 'Minutes', 
                                                angle: -90, 
                                                position: 'insideLeft',
                                                style: { fill: isDarkMode ? '#fff' : '#000' }
                                            }}
                                            domain={[0, (dataMax: number) => Math.max(10, Math.ceil(dataMax * 1.2))]}
                                            allowDecimals={false}
                                        />
                                        <Tooltip
                                            contentStyle={{
                                                backgroundColor: isDarkMode ? '#374151' : '#fff',
                                                border: 'none',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                            }}
                                            labelStyle={{
                                                color: isDarkMode ? '#fff' : '#000',
                                            }}
                                            formatter={(value: number) => [
                                                value === 0 ? 'No revision' : `${value} minutes`,
                                                'Time Spent'
                                            ]}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="minutesDone"
                                            stroke="#8B5CF6"
                                            strokeWidth={3}
                                            dot={{ fill: '#8B5CF6', r: 6 }}
                                            activeDot={{ r: 8, strokeWidth: 2 }}
                                            animationDuration={1000}
                                            animationEasing="ease-in-out"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-[400px] text-gray-500 dark:text-gray-400">
                                <BarChart2 className="w-16 h-16 mb-4 opacity-50" />
                                <p className="text-lg">No revision data available yet.</p>
                                <p className="text-sm opacity-75">Complete some revision sessions to see your progress!</p>
                            </div>
                        )}
                    </animated.div>
                </div>
            )}
        </>
    );
};

export default UserProfile;