import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { AlertCircle, Check, KeyRound } from 'lucide-react';
import { ChangeEvent, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { animated, useSpring } from 'react-spring';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import Url, { getWebsiteUrl } from '../../utils/Url';
import { validate400Errors, validateEmail, validateHCaptcha } from '../../utils/validationUtils';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState<string>('');
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const {isDarkMode, theme} = useTheme();

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    const handleVerificationSuccess = (token: string) => {
        setHecaptchaToken(token);
        setError('');
    };

    const handleResetPasswordRequest = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) {
            setError(captchaError);
            return;
        }

        setIsRequesting(true);
        setError('');
        setSuccessMessage('');

        try {
            const resetPasswordLink = `${window.location.origin}/reset-password/`;
            const response = await axios.post(AuthAPIUrls.REQUEST_PASSWORD_RESET, {
                email,
                resetPasswordLink,
                hcaptchaToken,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                setSuccessMessage(response.data || 'Check your email for the password reset link');
                setEmail(''); // Clear email field after successful request
                setHecaptchaToken(''); // Reset captcha
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            const validationError = validate400Errors(error);
                            setError(validationError || 'Invalid email format');
                            break;
                        case 404:
                            setError('No account found with this email address');
                            break;
                        case 429:
                            setError('Too many requests. Please try again later');
                            break;
                        case 500:
                            if (error.response.data?.includes('FailedToSendRestPasswordLink')) {
                                setError('Unable to send reset password email. Please try again later');
                            } else if (error.response.data?.includes('FailedToInjectPassword')) {
                                setError('System error: Failed to process password reset');
                            } else {
                                setError('An unexpected error occurred. Please try again later');
                            }
                            break;
                        default:
                            setError('An error occurred while processing your request');
                    }
                } else if (error.request) {
                    setError('Unable to connect to the server. Please check your internet connection');
                }
            } else {
                setError('An unexpected error occurred');
            }
            console.error('Password reset request error:', error);
        } finally {
            setIsRequesting(false);
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Reset Password Request</title>
            <meta name="description" content="Request a password reset link"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.RESET_PASSWORD_REQUEST}/>
        </Helmet>
        <div className={`min-h-screen flex flex-col ${theme.background} ${theme.text}`}>
            <div className="flex items-center justify-center flex-1">
                <div className="w-full max-w-2xl px-4 my-8">
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="flex items-center mb-6 space-x-4">
                            <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
                                <KeyRound className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}/>
                            </div>
                            <h2 className={`text-xl font-semibold ${theme.text}`}>
                                Reset Password Request
                            </h2>
                        </div>

                        <form onSubmit={(e) => { e.preventDefault(); handleResetPasswordRequest(); }} className="space-y-6">
                            <div>
                                <label htmlFor="email"
                                    className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            <div className="flex justify-center">
                                <HCaptcha
                                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                                    onVerify={(token) => handleVerificationSuccess(token)}
                                    theme={isDarkMode ? 'dark' : 'light'}
                                />
                            </div>

                            {error && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{error}</span>
                                </div>
                            )}

                            {successMessage && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{successMessage}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={isRequesting}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isRequesting ? (
                                    <div className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    'Request Reset Link'
                                )}
                            </button>
                        </form>
                    </animated.div>
                </div>
            </div>
        </div>
    </>;
};

export default ResetPasswordRequest;
