import React, { createContext, useContext, useState } from 'react';

interface FooterContextType {
    showFooter: boolean;
    setShowFooter: (show: boolean) => void;
}

const FooterContext = createContext<FooterContextType | undefined>(undefined);

export const FooterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showFooter, setShowFooter] = useState(true);

    return (
        <FooterContext.Provider value={{ showFooter, setShowFooter }}>
            {children}
        </FooterContext.Provider>
    );
};

export const useFooter = () => {
    const context = useContext(FooterContext);
    if (context === undefined) {
        throw new Error('useFooter must be used within a FooterProvider');
    }
    return context;
};
