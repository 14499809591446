export const useAuthStyles = (isDarkMode: boolean) => ({
    container: {
        background: isDarkMode ? authColors.dark.background : authColors.light.background,
    },
    card: {
        background: isDarkMode ? authColors.dark.cardBg : authColors.light.cardBg,
        boxShadow: `0 0 30px ${isDarkMode 
            ? 'rgba(134, 194, 50, 0.2)' 
            : 'rgba(13, 110, 253, 0.2)'}`,
        backdropFilter: 'blur(10px)',
    },
    input: {
        background: isDarkMode ? authColors.dark.inputBg : authColors.light.inputBg,
        border: `2px solid ${isDarkMode ? authColors.dark.border : authColors.light.border}`,
        color: isDarkMode ? authColors.dark.text : authColors.light.text,
        boxShadow: `0 0 10px ${isDarkMode 
            ? 'rgba(134, 194, 50, 0.2)' 
            : 'rgba(13, 110, 253, 0.2)'}`,
    },
    button: {
        background: isDarkMode 
            ? `linear-gradient(45deg, ${authColors.dark.primary}, ${authColors.dark.secondary})` 
            : `linear-gradient(45deg, ${authColors.light.primary}, ${authColors.light.secondary})`,
        border: `2px solid ${isDarkMode ? authColors.dark.border : authColors.light.border}`,
        color: isDarkMode ? authColors.dark.text : '#FFFFFF',
        boxShadow: `0 0 20px ${isDarkMode 
            ? 'rgba(134, 194, 50, 0.3)' 
            : 'rgba(13, 110, 253, 0.3)'}`,
    },
    link: {
        color: isDarkMode ? authColors.dark.primary : authColors.light.primary,
    }
});

export const authColors = {
    dark: {
        primary: '#86C232',
        secondary: '#61892F',
        background: 'linear-gradient(170deg, #0a192f 0%, #20232a 100%)',
        cardBg: 'rgba(32, 35, 42, 0.8)',
        text: '#FFFFFF',
        inputBg: 'rgba(30, 30, 30, 0.7)',
        border: '#86C232',
    },
    light: {
        primary: '#0D6EFD',
        secondary: '#0B5ED7',
        background: 'linear-gradient(170deg, #f0f0f0 0%, #ffffff 100%)',
        cardBg: 'rgba(255, 255, 255, 0.8)',
        text: '#130200',
        inputBg: 'rgba(255, 255, 255, 0.9)',
        border: '#0D6EFD',
    }
} as const;