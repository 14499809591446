import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaCalculator } from "@react-icons/all-files/fa/FaCalculator";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaHome } from "@react-icons/all-files/fa/FaHome";
import { FaMoon } from "@react-icons/all-files/fa/FaMoon";
import { FaSignInAlt } from "@react-icons/all-files/fa/FaSignInAlt";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaSun } from "@react-icons/all-files/fa/FaSun";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaUserPlus } from "@react-icons/all-files/fa/FaUserPlus";
import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import Url from "../utils/Url";
import useAuth from "../utils/useAuth";
import { useTheme } from "./ThemeContext";

interface NavBarProps {
    role?: string | null;
}

const NavBar: React.FC<NavBarProps> = ({ role }) => {
    const { isDarkMode, toggleTheme, theme } = useTheme();
    const navigate = useNavigate();
    const { jwt, logout } = useAuth(navigate);
    const [isOpen, setIsOpen] = useState(false);
    const [isMathMenuOpen, setMathMenuOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
    const location = useLocation();
    const mathMenuRef = useRef<HTMLDivElement>(null);

    // State for dynamic padding
    const [paddingClass, setPaddingClass] = useState("pt-16"); // Set the initial padding

    const toggleMenu = () => setIsOpen(!isOpen);

    const navItemClass = `px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
        isDarkMode
            ? 'text-gray-300 hover:bg-gray-700 hover:text-white'
            : 'text-gray-700 hover:bg-gray-200 hover:text-black'
    }`;

    const dropdownItemClass = `block px-4 py-2 text-sm transition-colors duration-200 ${
        isDarkMode ? 'text-gray-300 hover:bg-gray-600' : 'text-gray-700 hover:bg-gray-100'
    }`;

    interface NavLinkProps {
        to: string;
        onClick?: () => void;
        className?: string;
        children: React.ReactNode;
    }

    const NavLink: React.FC<NavLinkProps> = ({ to, onClick, className, children }) => {
        return (
            <Link to={to} onClick={onClick} className={className}>
                {children}
            </Link>
        );
    };

    const isActive = (path: string) => location.pathname === path ? 'text-green-500' : '';

    const logoPath = isDarkMode ? "/logos/dark_mode_logo.svg" : "/logos/white_mode_logo.svg";

    const handleMouseEnter = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setMathMenuOpen(true);
    };

    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setMathMenuOpen(false);
        }, 300);
        setHoverTimeout(timeoutId);
    };

    // Update padding based on menu state
    useEffect(() => {
        setPaddingClass(isOpen ? "pt-16" : "pt-0"); // Adjust this based on your layout
    }, [isOpen]);

    const handleLogout = () => {
        logout(); // This will now clear both role and dyslexia caches
        toggleMenu();
    };

    return (
        <nav className={`sticky top-0 left-0 right-0 z-50 ${theme.cardBg} shadow-lg transition-colors duration-200`}>
            <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${paddingClass}`}>
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <Link to={Url.HOME} className="flex-shrink-0">
                            <img className="w-8 h-8" src={logoPath} alt="Logo" />
                        </Link>
                    </div>
                    <div className="hidden md:block">
                        <div className={`flex items-baseline ml-10 space-x-4 ${theme.text}`}>
                            <NavLink to={Url.HOME} className={`${isActive(Url.HOME)} ${navItemClass}`}>
                                <FaHome className="inline mr-1" /> Home
                            </NavLink>

                            {jwt ? (
                                <>
                                    <div
                                        className="relative group"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        ref={mathMenuRef}
                                    >
                                        <button className={`${isActive(Url.MATH)} ${navItemClass}`}>
                                            <FaCalculator className="inline mr-1" /> Math
                                        </button>
                                        {isMathMenuOpen && (
                                            <div
                                                className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 transform transition-all duration-300 ease-in-out ${
                                                    isDarkMode ? 'bg-gray-700' : 'bg-white'
                                                } bubble-effect`}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <NavLink to={Url.MATH} className={dropdownItemClass}>Math Home</NavLink>
                                                <NavLink to={Url.MATH_EXAM_PAPER_RETRIEVAL} className={dropdownItemClass}>
                                                    Exam Paper Retrieval
                                                </NavLink>
                                                <NavLink to={Url.MATH_EXAM_PAPER_BUILDER} className={dropdownItemClass}>
                                                    Exam Paper Builder
                                                </NavLink>
                                                <NavLink to={Url.MATH_TOPIC_PAPER_GENERATOR} className={dropdownItemClass}>
                                                    Topic Paper Generator
                                                </NavLink>
                                                {role === 'ADMIN' && (
                                                    <NavLink to={Url.MATH_EXAM_QUESTION_UPLOADER} className={dropdownItemClass}>
                                                        Question Uploader
                                                    </NavLink>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <NavLink to={Url.USER_PAGE} className={`${isActive(Url.USER_PAGE)} ${navItemClass}`}>
                                        <FaUser className="inline mr-1" /> User
                                    </NavLink>

                                    <NavLink to={Url.SETTINGS_PAGE} className={`${isActive(Url.SETTINGS_PAGE)} ${navItemClass}`}>
                                        <FaCog className="inline mr-1" /> Settings
                                    </NavLink>

                                    <NavLink to={Url.REVISION_TIMER} className={`${isActive(Url.REVISION_TIMER)} ${navItemClass}`}>
                                        <FaTimes className="inline mr-1" /> Revision Timer
                                    </NavLink>

                                    <button onClick={handleLogout} className={navItemClass}>
                                        <FaSignOutAlt className="inline mr-1" /> Logout
                                    </button>
                                </>
                            ) : (
                                <>
                                    <NavLink to={Url.LOGIN} className={navItemClass}>
                                        <FaSignInAlt className="inline mr-1" /> Login
                                    </NavLink>
                                    <NavLink to={Url.SIGN_UP} className={navItemClass}>
                                        <FaUserPlus className="inline mr-1" /> Sign Up
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-md transition-colors duration-200 ${
                                isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                        >
                            {isDarkMode ? <FaSun className="text-amber-400"/> : <FaMoon className="text-gray-700"/>}
                        </button>
                        <div className="flex -mr-2 md:hidden">
                            <button
                                onClick={toggleMenu}
                                className={`inline-flex items-center justify-center p-2 rounded-md transition-colors duration-200 ${
                                    isDarkMode ? 'text-gray-400 hover:text-white hover:bg-gray-700' : 'text-gray-700 hover:text-black hover:bg-gray-200'
                                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                            >
                                <span className="sr-only">Open main menu</span>
                                {isOpen ? <FaTimes className="block w-6 h-6" /> : <FaBars className="block w-6 h-6" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className={`md:hidden ${theme.cardBg} transition-colors duration-200`}>
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <NavLink to={Url.HOME} onClick={toggleMenu} className={`${isActive(Url.HOME)} ${navItemClass} w-full text-left`}>
                            <FaHome className="inline mr-1" /> Home
                        </NavLink>
                        {jwt ? (
                            <>
                                <div
                                    className="relative group"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    ref={mathMenuRef}
                                >
                                    <button className={`${isActive(Url.MATH)} ${navItemClass} w-full text-left`}>
                                        <FaCalculator className="inline mr-1"/> Math
                                    </button>
                                    {isMathMenuOpen && (
                                        <div
                                            className="pl-4 space-y-1 transition-all duration-300 ease-in-out transform bubble-effect">
                                            <NavLink to={Url.MATH} onClick={toggleMenu} className={dropdownItemClass}>Math
                                                Home</NavLink>
                                            <NavLink to={Url.MATH_EXAM_PAPER_RETRIEVAL} onClick={toggleMenu}
                                                     className={dropdownItemClass}>
                                                Exam Paper Retrieval
                                            </NavLink>
                                            <NavLink to={Url.MATH_EXAM_PAPER_BUILDER} onClick={toggleMenu}
                                                     className={dropdownItemClass}>
                                                Exam Paper Builder
                                            </NavLink>
                                            <NavLink to={Url.MATH_TOPIC_PAPER_GENERATOR} onClick={toggleMenu}
                                                     className={dropdownItemClass}>
                                                Topic Paper Generator
                                            </NavLink>
                                            {role === 'ADMIN' && (
                                                <NavLink to={Url.MATH_EXAM_QUESTION_UPLOADER} onClick={toggleMenu}
                                                         className={dropdownItemClass}>
                                                    Question Uploader
                                                </NavLink>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <NavLink to={Url.USER_PAGE} onClick={toggleMenu} className={`${isActive(Url.USER_PAGE)} ${navItemClass} block w-full text-left`}>
                                        <FaUser className="inline mr-1"/> User
                                    </NavLink>

                                    <NavLink to={Url.SETTINGS_PAGE} onClick={toggleMenu} className={`${isActive(Url.SETTINGS_PAGE)} ${navItemClass} block w-full text-left`}>
                                        <FaCog className="inline mr-1"/> Settings
                                    </NavLink>

                                    
                                    <NavLink to={Url.REVISION_TIMER} onClick={toggleMenu} className={`${isActive(Url.REVISION_TIMER)} ${navItemClass} block w-full text-left`}>
                                        <FaTimes className="inline mr-1"/> Revision Timer
                                    </NavLink>
                                </div>

                                <button onClick={handleLogout} className={`${navItemClass} w-full text-left`}>
                                    <FaSignOutAlt className="inline mr-1"/> Logout
                                </button>
                            </>
                        ) : (
                            <>
                                <NavLink to={Url.LOGIN} onClick={toggleMenu} className={navItemClass}>
                                    <FaSignInAlt className="inline mr-1" /> Login
                                </NavLink>
                                <NavLink to={Url.SIGN_UP} onClick={toggleMenu} className={navItemClass}>
                                    <FaUserPlus className="inline mr-1" /> Sign Up
                                </NavLink>
                            </>
                        )}
                    </div>
                </div>
            )}

            <style>
                {`
                    .bubble-effect {
                        transform-origin: top;
                        animation: bubbleIn 0.2s ease-out;
                    }
                    
                    @keyframes bubbleIn {
                        0% {
                            transform: scale(0.95);
                            opacity: 0;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                `}
            </style>
        </nav>
    );
};

export default memo(NavBar);