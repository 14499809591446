import axios from 'axios';
import { AlertCircle, Check, KeyRound } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router';
import { animated, useSpring } from 'react-spring';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import { getCurrentStyles } from "../../utils/Common";
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validate400Errors, validateEmail, validatePassword, validatePasswordMatch } from '../../utils/validationUtils';

const ResetPassword = () => {
    const {token} = useParams<{ token: string }>();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');  // Add this line
    const [isResetting, setIsResetting] = useState(false);
    const [isValidating, setIsValidating] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const navigate = useNavigate();
    const {isDarkMode, theme} = useTheme();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.post(AuthAPIUrls.VERIFY_PASSWORD_REST_TOKEN, {
                    token: token,
                    url: getWebsiteUrl()
                });

                if (response.status === 200) {
                    setIsTokenValid(true);
                } else {
                    setError('Invalid or expired password reset link');
                }
            } catch (error: any) {
                setError('Invalid or expired password reset link');
                setTimeout(() => {
                    navigate('/404');
                }, 3000);
            } finally {
                setIsValidating(false);
            }
        };

        if (token) {
            validateToken().then(r => r);
        } else {
            setError('Invalid password reset link');
            setTimeout(() => {
                navigate('/404');
            }, 3000);
        }
    }, [token, navigate]);

    const handleResetPassword = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        const passwordMatchError = validatePasswordMatch(newPassword, confirmPassword);
        if (passwordMatchError) {
            setError(passwordMatchError);
            return;
        }

        setIsResetting(true);
        setError('');

        try {
            const response = await axios.post(AuthAPIUrls.FULFILL_PASSWORD_RESET, {
                email: email,
                token: token,
                newPassword: newPassword,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                setSuccess('Password reset successful. Redirecting to login...');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                const errorData = error.response?.data;
                
                switch (error.response?.status) {
                    case 404:
                        if (errorData === 'The rest password token has expired as it does not exist') {
                            setError('Your password reset link has expired. Please request a new one.');
                            setTimeout(() => {
                                navigate('/reset-password-request');
                            }, 3000);
                        }
                        break;
                    case 401:
                        if (errorData === 'The rest password token is not associated with this email.') {
                            setError('This email does not match the reset token. Please use the email associated with the reset link.');
                        }
                        break;
                    case 400:
                        setError(validate400Errors(error));
                        break;
                    default:
                        setError('An error occurred during password reset. Please try again later.');
                }

                // Log the error for debugging
                console.error('Password reset error:', {
                    status: error.response?.status,
                    data: errorData
                });
            } else {
                setError('Unable to connect to the server. Please check your internet connection.');
            }
        } finally {
            setIsResetting(false);
        }
    };

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    if (isValidating) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="h-12 border-4 border-green-600 rounded-full w -12 animate-spin border-t-transparent"></div>
            </div>
        );
    }

    if (!isTokenValid) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Reset Password</title>
                <meta name="description" content="Reset your Revise Wizard account password"/>
                <link rel="canonical" href={getWebsiteUrl() + Url.RESET_PASSWORD}/>
            </Helmet>
            
            <div className={`min-h-screen flex items-center justify-center py-4 ${theme.background} ${theme.text}`}>
                <div className="w-full max-w-2xl px-4">
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="flex items-center mb-6 space-x-4">
                            <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
                                <KeyRound className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}/>
                            </div>
                            <h2 className={`text-xl font-semibold ${theme.text}`}>
                                Reset Password
                            </h2>
                        </div>

                        <form onSubmit={(e) => { e.preventDefault(); handleResetPassword(); }} className="space-y-6">
                            <div>
                                <label htmlFor="email"
                                       className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="newPassword"
                                       className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="confirmPassword"
                                       className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            {error && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{error}</span>
                                </div>
                            )}

                            {success && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{success}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={isResetting}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isResetting ? (
                                    <div className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                        </form>
                    </animated.div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
