import { ThemeConfig } from '../components/ThemeContext';

export const getCommonStyles = (isDarkMode: boolean) => ({
    background: isDarkMode 
        ? 'linear-gradient(170deg, #0a192f 0%, #20232a 100%)'
        : 'linear-gradient(170deg, #f0f0f0 0%, #ffffff 100%)',
    transition: 'all 0.3s ease'
});

export const getButtonStyles = (theme: ThemeConfig) => ({
    backgroundColor: theme.buttonBg.replace('bg-', ''),
    color: theme.buttonText.replace('text-', ''),
    transition: 'all 0.3s ease'
});

export const getInputStyles = (theme: ThemeConfig) => ({
    backgroundColor: theme.inputBg,
    color: theme.inputText,
    borderColor: theme.border,
    transition: 'all 0.3s ease'
});

export const getCardStyles = (theme: ThemeConfig) => ({
    backgroundColor: theme.cardBg === 'bg-white' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(31, 41, 55, 0.9)',
    backdropFilter: 'blur(10px)',
    borderColor: theme.border.replace('border-', ''),
    transition: 'all 0.3s ease'
});
