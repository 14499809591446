import axios from 'axios';
import { AlertCircle, Check, KeyRound } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { animated, useSpring } from 'react-spring';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import { setJWT } from "../../utils/Cookies";
import Url, { getWebsiteUrl } from "../../utils/Url";

const Verification: React.FC = () => {
    const {email, verificationType} = useParams<{ email: string; verificationType: string; }>();

    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [info, setInfo] = useState(''); // State variable to show info message
    const [isVerifying, setIsVerifying] = useState(false); // State to track verification process
    const navigate = useNavigate();
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const {isDarkMode, theme} = useTheme();

    const handleVerification = async () => {
        setIsVerifying(true);
        try {
            const response = await axios.post(AuthAPIUrls.VERIFY, {
                email: email,
                verificationCode: verificationCode.join(''),
                type: verificationType,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                if (verificationType === 'signup') {
                    setInfo('Successfully verified, logging in');
                    let jwt = response.data;
                    setJWT('jwt', jwt);
                    navigate('/');
                    window.location.reload();
                } else {
                    setInfo('Successfully verified, logging in');
                    let jwt = response.data;
                    setJWT('jwt', jwt);
                    navigate('/');
                    window.location.reload();
                }

                setError('');
                setInfo('');
                setVerificationCode(['', '', '', '', '', '']);
            } else {
                // Handle unsuccessful verification
                console.error('Error during verification:', response.data);
                setError('Invalid verification code');
            }
        } catch (error) {
            // Handle error
            console.error('Error during verification:', error);
            setError('Invalid verification code');
        } finally {
            setIsVerifying(false);
        }
    };

    const handleClearCode = () => {
        setVerificationCode(['', '', '', '', '', '']);
        // Focus on first input after clearing
        inputRefs.current[0]?.focus();
    };

    // Function to handle the change in each input box
    const handleChange = (index: number, value: string) => {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);

        // Move focus to next input box if value is entered
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    // Function to handle key down event
    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Function to handle paste event
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text');
        const pastedData = clipboardData.replace(/\s+/g, '').slice(0, 6);
        const pastedChars = pastedData.split('');
        const newVerificationCode = [...verificationCode];
        for (let i = 0; i < pastedChars.length; i++) {
            newVerificationCode[i] = pastedChars[i];
            if (inputRefs.current[i]) {
                inputRefs.current[i].value = pastedChars[i];
            }
        }
        setVerificationCode(newVerificationCode);
        if (pastedChars.length < 6) {
            inputRefs.current[pastedChars.length].focus();
        }
    };

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    return <>
        <Helmet>
            <title>Revise Wizard - Verification</title>
            <meta name="description" content="Input verification code to verify your account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.SIGN_UP}/>
        </Helmet>
        <div className={`min-h-screen flex items-center justify-center py-4 ${theme.background} ${theme.text}`}>
            <div className="w-full max-w-2xl px-4">
                <animated.div 
                    style={fadeIn}
                    className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                              bg-opacity-90 hover:shadow-xl hover-transform`}
                >
                    <div className="flex items-center mb-6 space-x-4">
                        <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
                            <KeyRound className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}/>
                        </div>
                        <h2 className={`text-xl font-semibold ${theme.text}`}>
                            Verification Code
                        </h2>
                    </div>

                    <div className="flex flex-col items-center space-y-6">
                        <div className="flex justify-center space-x-2">
                            {Array.from({length: 6}, (_, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={verificationCode[index]}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onPaste={handlePaste}
                                    ref={el => inputRefs.current[index] = el!}
                                    className={`w-12 h-12 text-center text-xl border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white' 
                                            : 'bg-white border-gray-300 text-gray-900'
                                    }`}
                                />
                            ))}
                        </div>

                        <div className="flex items-center space-x-4">
                            <button
                                onClick={handleClearCode}
                                type="button"
                                className={`px-4 py-2 text-sm font-medium transition-colors rounded-md ${
                                    isDarkMode 
                                        ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' 
                                        : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                                }`}
                            >
                                Clear Code
                            </button>

                            <button
                                onClick={handleVerification}
                                disabled={isVerifying}
                                className="flex items-center justify-center flex-1 px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isVerifying ? (
                                    <div className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    'Verify Code'
                                )}
                            </button>
                        </div>

                        {error && (
                            <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                <AlertCircle className="w-5 h-5"/>
                                <span>{error}</span>
                            </div>
                        )}

                        {info && (
                            <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                <Check className="w-5 h-5"/>
                                <span>{info}</span>
                            </div>
                        )}

                    </div>
                </animated.div>
            </div>
        </div>
    </>;
};

export default Verification;
