import React, { useMemo } from 'react';
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import { ReactTyped } from "react-typed";
import { authColors } from '../components/auth/AuthStyles';
import { useTheme } from "../components/ThemeContext";
import { getCurrentButtonStyles } from "../utils/Common";
import { getCookie } from "../utils/Cookies";
import Url, { getWebsiteUrl } from "../utils/Url";

const Home: React.FC = () => {
    const jwtToken = getCookie('jwt');
    const { isDarkMode, theme } = useTheme();  // Add theme here

    const currentBoldTextStyles = useMemo(() => ({
        fontWeight: 'bold',
        color: isDarkMode ? authColors.dark.primary : '#130200',
        textShadow: `0 0 15px ${isDarkMode ? 'rgba(134, 194, 50, 0.5)' : 'rgba(19, 2, 0, 0.3)'}`,
        letterSpacing: '1px'
    }), [isDarkMode]);

    const logoPath = useMemo(() => isDarkMode ? "/logos/dark_mode_logo.svg" : "/logos/white_mode_logo.svg", [isDarkMode]);

    const subjects = useMemo(() => [
        'We support: Math',
        'Coming Soon: Economics (April 2025)',
        'Coming Soon: Computer Science (April 2025)'
    ], []);

    const getButtonStyles = (isDarkMode: boolean) => ({
        ...getCurrentButtonStyles(isDarkMode),
        background: isDarkMode 
            ? `linear-gradient(45deg, ${authColors.dark.primary}, ${authColors.dark.secondary})` 
            : 'linear-gradient(45deg, #f0f0f0, #ffffff)',
        boxShadow: `0 0 20px ${isDarkMode 
            ? 'rgba(134, 194, 50, 0.3)' 
            : 'rgba(19, 2, 0, 0.2)'}`,
        border: `2px solid ${isDarkMode ? authColors.dark.border : '#130200'}`,
        color: isDarkMode ? authColors.dark.text : '#130200'
    });

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Home</title>
                <meta name="description"
                      content="The home page of Revise Wizard, a platform that aims to increase productivity by cutting prep time and distractions."/>
                <link rel="canonical" href={getWebsiteUrl() + Url.HOME}/>
            </Helmet>
            <div className={`relative flex flex-col items-center flex-grow px-4 py-6 overflow-hidden sm:px-6 md:px-8 ${theme.background} ${theme.text}`}
                 style={{
                     background: isDarkMode 
                         ? authColors.dark.background
                         : authColors.light.background,
                 }}>
                
                {/* Animated cyber grid background */}
                <div className="absolute inset-0" style={{
                    backgroundImage: `radial-gradient(${isDarkMode ? authColors.dark.primary : '#130200'} 1px, transparent 1px)`,
                    backgroundSize: '50px 50px',
                    opacity: 0.1,
                    animation: 'gridMove 20s linear infinite'
                }}/>

                {/* Geometric shapes */}
                <div className="absolute top-0 right-0 w-64 h-64 transform rotate-45 translate-x-32 -translate-y-32"
                     style={{
                         background: isDarkMode 
                             ? `linear-gradient(45deg, ${authColors.dark.primary} 0%, transparent 60%)`
                             : 'linear-gradient(45deg, #130200 0%, transparent 60%)',
                         opacity: 0.1,
                         filter: 'blur(40px)'
                     }}/>

                <div className="relative z-10 flex flex-col items-center justify-center flex-1">
                    <div className="w-full max-w-xs transition-all duration-500 transform sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl hover:scale-105"
                         style={{
                             animation: 'float 6s ease-in-out infinite',
                             filter: `drop-shadow(0 0 20px ${isDarkMode ? 'rgba(134, 194, 50, 0.3)' : 'rgba(19, 2, 0, 0.2)'})`
                         }}>
                        <img src={logoPath} alt="Revise Wizard Logo" className="logo-image" loading="lazy"/>
                    </div>

                    <div className="w-full">
                        <h1 className={`mb-3 text-2xl font-bold text-center transition-colors sm:text-3xl md:text-4xl lg:text-5xl hover:tracking-wider ${theme.text}`}
                            style={{
                                color: isDarkMode ? authColors.dark.primary : '#130200',
                                textShadow: isDarkMode 
                                    ? '0 0 20px rgba(134, 194, 50, 0.3)' 
                                    : '0 0 20px rgba(19, 2, 0, 0.2)'
                            }}>
                            Welcome to <span>Revise Wizard</span>
                        </h1>
                    </div>

                    <div className="text-lg font-bold text-center sm:text-xl md:text-2xl"
                         style={currentBoldTextStyles}>
                        <ReactTyped
                            strings={subjects}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                            className="ml-2"
                            style={{color: currentBoldTextStyles.color}}
                        />
                    </div>

                    {!jwtToken && (
                        <div className="flex justify-center mt-8 space-x-6">
                            <Link to={Url.SIGN_UP}>
                                <button
                                    className="relative px-8 py-4 overflow-hidden transition-all duration-300 transform rounded-lg hover:scale-105"
                                    style={getButtonStyles(isDarkMode)}>
                                    <span className="relative z-10">Begin Your Journey</span>
                                </button>
                            </Link>
                            <Link to={Url.LOGIN}>
                                <button
                                    className="relative px-8 py-4 overflow-hidden transition-all duration-300 transform rounded-lg hover:scale-105"
                                    style={getButtonStyles(isDarkMode)}>
                                    <span className="relative z-10">Login Now</span>
                                </button>
                            </Link>
                        </div>
                    )}

                    {jwtToken && (
                        <div className="flex justify-center mt-4 space-x-4">
                            <Link to={Url.MATH}>
                                <button
                                    className="relative px-8 py-4 overflow-hidden transition-all duration-300 transform rounded-lg hover:scale-105"
                                    style={getButtonStyles(isDarkMode)}>
                                    <span className="relative z-10">Go to Math</span>
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <style>
                {`
                @keyframes gridMove {
                    0% { transform: translateY(0); }
                    100% { transform: translateY(50px); }
                }
                
                @keyframes float {
                    0%, 100% { transform: translateY(0) rotate(-2deg); }
                    50% { transform: translateY(-20px) rotate(2deg); }
                }
                
                .typed-cursor {
                    font-size: 1.5em;
                    opacity: 1;
                    animation: blink 0.7s infinite;
                    color: ${isDarkMode ? authColors.dark.primary : '#130200'};
                    text-shadow: 0 0 10px ${isDarkMode 
                        ? 'rgba(134, 194, 50, 0.5)' 
                        : 'rgba(19, 2, 0, 0.3)'};
                }
                
                @keyframes blink {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }
                
                button:hover {
                    box-shadow: 0 0 30px ${isDarkMode 
                        ? 'rgba(134, 194, 50, 0.5)' 
                        : 'rgba(19, 2, 0, 0.4)'};
                    border-color: ${isDarkMode ? authColors.dark.border : '#130200'};
                    background: ${isDarkMode 
                        ? `linear-gradient(45deg, ${authColors.dark.secondary}, ${authColors.dark.primary})` 
                        : 'linear-gradient(45deg, #ffffff, #f0f0f0)'};
                }

                /* Remove all theme transitions except for hover effects */
                * {
                    transition: none !important;
                }
                
                /* Keep hover transitions only */
                button {
                    transition: transform 0.2s ease, box-shadow 0.2s ease !important;
                }
                
                .logo-image {
                    transition: transform 0.2s ease !important;
                }
                
                /* Remove the other transition styles */
                `}
            </style>
        </>
    );
};

export default React.memo(Home);