import { AlertCircle, Check, KeyRound, User } from 'lucide-react';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { animated, useSpring } from 'react-spring';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls, UserInfoAPIUrls } from "../../utils/APIUrls";
import { getCurrentStyles } from "../../utils/Common";
import { getCookie } from "../../utils/Cookies";
import { checkServerStatus, getDyslexiaModeFromCache, setDyslexiaMode } from '../../utils/DyslexiaUtils';
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validatePassword, validatePasswordMatch } from "../../utils/validationUtils";

interface PasswordChangeForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface UsernameUpdateRequest {
    name: string;
}


const SettingsPage = () => {
    const { isDarkMode, theme } = useTheme();
    const jwtToken = getCookie('jwt');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isPasswordNull, setIsPasswordNull] = useState<boolean | null>(null);
    const [passwordForm, setPasswordForm] = useState<PasswordChangeForm>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [usernameSuccess, setUsernameSuccess] = useState<string | null>(null);
    const [isDyslexicMode, setIsDyslexicMode] = useState(() => {
        const cachedMode = getDyslexiaModeFromCache();
        return cachedMode ?? false;
    });
    const [dyslexicError, setDyslexicError] = useState<string | null>(null);
    const [dyslexicSuccess, setDyslexicSuccess] = useState<string | null>(null);

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    useEffect(() => {
        const checkPasswordStatus = async () => {
            try {
                const response = await fetch(AuthAPIUrls.IS_PASSWORD_NULL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to check password status');
                }

                const isNull = await response.json();
                setIsPasswordNull(isNull);
            } catch (err) {
                setError('Failed to check password status');
            }
        };

        checkPasswordStatus();
    }, [jwtToken]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswordForm(prev => ({
            ...prev,
            [name]: value
        }));
        setError(null);
        setSuccess(null);
    };

    const validateForm = (): boolean => {
        const passwordError = validatePassword(passwordForm.newPassword);
        if (passwordError) {
            setError(passwordError);
            return false;
        }

        const passwordMatchError = validatePasswordMatch(passwordForm.newPassword, passwordForm.confirmPassword);
        if (passwordMatchError) {
            setError(passwordMatchError);
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);
        try {
            const response = await fetch(AuthAPIUrls.CHANGE_PASSWORD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: JSON.stringify({
                    currentPassword: isPasswordNull ? null : passwordForm.currentPassword,
                    newPassword: passwordForm.newPassword
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to change password');
            }

            setSuccess('Password successfully changed');
            setPasswordForm({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
            setIsPasswordNull(false);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Failed to change password. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDyslexicToggle = async () => {
        try {
            const isServerUp = await checkServerStatus();
            if (!isServerUp) {
                setDyslexicError('Server is currently unavailable. Changes will be synced when connection is restored.');
                return;
            }

            const response = await fetch(UserInfoAPIUrls.DYSLEXIC, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: new URLSearchParams({
                    is_dyslexic: (!isDyslexicMode).toString()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update dyslexic mode');
            }

            const newMode = !isDyslexicMode;
            setIsDyslexicMode(newMode);
            setDyslexiaMode(newMode);
            setDyslexicSuccess('Dyslexic mode updated successfully');
            setDyslexicError(null);
        } catch (err) {
            setDyslexicError('Failed to update dyslexic mode');
            setDyslexicSuccess(null);
        }
    };

    // Add this effect to apply dyslexic mode on page load
    useEffect(() => {
        if (isDyslexicMode) {
            document.body.classList.add('dyslexia-mode');
        } else {
            document.body.classList.remove('dyslexia-mode');
        }
    }, [isDyslexicMode]);

    if (isPasswordNull === null) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="w-12 h-12 border-4 border-green-600 rounded-full animate-spin border-t-transparent"></div>
            </div>
        );
    }

    const handleUsernameSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const requestBody: UsernameUpdateRequest = {
                name: username
            };

            const response = await fetch(UserInfoAPIUrls.NAME, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update username');
            }

            setUsernameSuccess('Username successfully updated');
            setUsername('');
        } catch (err) {
            if (err instanceof Error) {
                setUsernameError(err.message);
            } else {
                setUsernameError('Failed to update username. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Helmet>
                <title>Revise Wizard - Settings</title>
                <meta name="description" content="Manage your Revise Wizard account settings" />
                <link rel="canonical" href={getWebsiteUrl() + Url.SETTINGS_PAGE} />
            </Helmet>

            <div className={`min-h-screen flex flex-col items-center ${theme.background} ${theme.text}`}>
                <div className="w-full max-w-2xl px-4 py-8 space-y-8 md:py-12">
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="text-center">
                            <h1 className={`text-4xl font-bold bg-gradient-to-r from-green-600 to-blue-600 bg-clip-text text-transparent`}>
                                Account Settings
                            </h1>
                            <p className={`mt-3 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                Manage your account preferences and security
                            </p>
                        </div>
                    </animated.div>

                    {/* Username Section */}
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="flex items-center mb-6 space-x-4">
                            <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
                                <User className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}/>
                            </div>
                            <h2 className={`text-xl font-semibold ${theme.text}`}>
                                Update Username
                            </h2>
                        </div>

                        <form onSubmit={handleUsernameSubmit} className="space-y-6" autoComplete="off">
                            <div>
                                <label htmlFor="display-name"
                                    className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    New Username
                                </label>
                                <input
                                    type="text"
                                    id="display-name"
                                    name="display-name"
                                    autoComplete="off"
                                    spellCheck="false"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                        setUsernameError(null);
                                        setUsernameSuccess(null);
                                    }}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            {usernameError && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{usernameError}</span>
                                </div>
                            )}

                            {usernameSuccess && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{usernameSuccess}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {loading ? (
                                    <div
                                        className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    'Update Username'
                                )}
                            </button>
                        </form>
                    </animated.div>

                    {/* Password Section */}
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="flex items-center mb-6 space-x-4">
                            <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
                                <KeyRound className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}/>
                            </div>
                            <h2 className={`text-xl font-semibold ${theme.text}`}>
                                {isPasswordNull ? 'Set Password' : 'Change Password'}
                            </h2>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {!isPasswordNull && (
                                <div>
                                    <label htmlFor="currentPassword"
                                           className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                        Current Password
                                    </label>
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        name="currentPassword"
                                        value={passwordForm.currentPassword}
                                        onChange={handleInputChange}
                                        className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                            isDarkMode 
                                                ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                                : 'border-gray-300 focus:border-green-500'
                                        } focus:outline-none focus:ring-green-500`}
                                        required={!isPasswordNull}
                                    />
                                </div>
                            )}

                            <div>
                                <label htmlFor="newPassword"
                                       className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={passwordForm.newPassword}
                                    onChange={handleInputChange}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="confirmPassword"
                                       className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                    Confirm New Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={passwordForm.confirmPassword}
                                    onChange={handleInputChange}
                                    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm ${
                                        isDarkMode 
                                            ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                                            : 'border-gray-300 focus:border-green-500'
                                    } focus:outline-none focus:ring-green-500`}
                                    required
                                />
                            </div>

                            {error && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{error}</span>
                                </div>
                            )}

                            {success && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{success}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {loading ? (
                                    <div
                                        className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    isPasswordNull ? 'Set Password' : 'Change Password'
                                )}
                            </button>
                        </form>
                    </animated.div>

                    {/* Accessibility Section */}
                    <animated.div 
                        style={fadeIn}
                        className={`card p-8 shadow-lg ${theme.cardBg} ${theme.text} rounded-2xl backdrop-blur-sm 
                                  bg-opacity-90 hover:shadow-xl hover-transform`}
                    >
                        <div className="flex items-center mb-6 space-x-4">
                            <div className={`p-3 rounded-lg bg-gradient-to-br ${
                                isDarkMode ? 'from-purple-900 to-blue-900' : 'from-purple-100 to-blue-100'
                            }`}>
                                <svg className={`w-6 h-6 ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`} 
                                     fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                          d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                            </div>
                            <h2 className={`text-2xl font-semibold ${theme.text}`}>
                                Accessibility
                            </h2>
                        </div>

                        <div className={`p-6 rounded-xl ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-50'} backdrop-blur-sm`}>
                            <div className="flex items-center justify-between">
                                <div className="space-y-1">
                                    <h3 className={`font-medium ${theme.text}`}>Dyslexia-friendly mode</h3>
                                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                        Enables OpenDyslexic font and improved readability
                                    </p>
                                </div>
                                <button
                                    onClick={handleDyslexicToggle}
                                    className={`relative inline-flex h-7 w-14 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                                        isDyslexicMode 
                                            ? 'bg-gradient-to-r from-green-500 to-green-600 focus:ring-green-500' 
                                            : 'bg-gray-400 focus:ring-gray-400'
                                    }`}
                                    aria-label="Toggle dyslexia mode"
                                >
                                    <span
                                        className={`inline-block h-5 w-5 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                                            isDyslexicMode ? 'translate-x-7' : 'translate-x-1'
                                        }`}
                                    />
                                </button>
                            </div>

                            {dyslexicError && (
                                <div className="flex items-center mt-4 space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span className="text-sm">{dyslexicError}</span>
                                </div>
                            )}

                            {dyslexicSuccess && (
                                <div className="flex items-center mt-4 space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span className="text-sm">{dyslexicSuccess}</span>
                                </div>
                            )}
                        </div>
                    </animated.div>
                </div>
            </div>
        </>
    );
};

export default memo(SettingsPage);